import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from '../../services/alert.service';
import { IUserView } from '../../../../../server/src/interfaces/IUserView';
import { MembersService } from '../../services/members.service';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class AccountComponent implements OnInit {

  public me: IUserView;

  constructor(
    private _membersService: MembersService,
    private _modalService: NgbModal,
    private _alertService: AlertService,
  ) {
  }

  public ngOnInit() {
    this.load();
  }

  private load() {

    this._membersService.getMe()
      .subscribe(
        (response: IUserView) => {
          this.me = response;
        },
    );
  }

}
