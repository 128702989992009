import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PopupComponent } from './popup.component';
import { PopupStoreInfoComponent } from './components/popup-store-info/popup-store-info.component';
import { PopupSocialInfoComponent } from './components/popup-social-info/popup-social-info.component';
import { PopupProductInsightsComponent } from './components/popup-product-insights/popup-product-insights.component';
import { PopupInstalledAppsComponent } from './components/popup-installed-apps/popup-installed-apps.component';
import { PopupTrafficAnalysisComponent } from './components/popup-traffic-analysis/popup-traffic-analysis.component';
import { FacebookModule, FacebookService } from 'ngx-facebook';
import { ChartModule } from 'angular2-chartjs';
import { NgArrayPipesModule, NgObjectPipesModule } from 'ngx-pipes';
import { StoreAnalyticsModule } from '../store-analytics/store-analytics.module';
import { AngularTwitterTimelineModule } from "angular-twitter-timeline";

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    FacebookModule,
    ChartModule,
    NgArrayPipesModule,
    NgObjectPipesModule,
    StoreAnalyticsModule,
    AngularTwitterTimelineModule,
  ],
  declarations: [
    PopupComponent,
    PopupStoreInfoComponent,
    PopupSocialInfoComponent,
    PopupProductInsightsComponent,
    PopupInstalledAppsComponent,
    PopupTrafficAnalysisComponent,
  ],
  providers: [
    FacebookService,
  ],
  exports: [
    PopupComponent,
    PopupStoreInfoComponent,
    PopupSocialInfoComponent,
    PopupProductInsightsComponent,
    PopupInstalledAppsComponent,
    PopupTrafficAnalysisComponent,
  ]
})
export class PopupModule { }
