import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {
  @Input() collectionLength;
  @Input() pageSize;
  @Output() pageUpdate = new EventEmitter<number>();
  page: number;

  constructor() { }

  ngOnInit() {
    this.page = 1;
  }

  setPage(page) {
    this.page = page;
  }

  onPageChange() {
    this.pageUpdate.emit(this.page);
  }

}
