import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EtsyProductComponent } from './etsy-product.component';
import {RouterModule} from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    RouterModule
  ],
  declarations: [EtsyProductComponent],
  exports: [EtsyProductComponent]
})
export class EtsyProductModule { }
