import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { CarouselComponent } from './components/carousel/carousel.component';
import { ProductStoreFilterModule } from './components/filters/product-store-filter/product-store-filter.module';
import { AmazonEbayFilterModule } from './components/filters/amazon-ebay-filter/amazon-ebay-filter.module';
import { BookmarkedFilterModule } from './components/filters/bookmarked-filter/bookmarked-filter.module';
import { PaginationModule } from './components/pagination/pagination.module';
import { BackendService } from './services/backend.service';
import { PopupModule } from './components/popup/popup.module';
import { BookmarkSharedService } from './services/bookmark-shared.service';

import { InsideSpinnerModule } from './inside-spinner/inside-spinner.module';
import { EtsyProductModule } from './components/etsy-product/etsy-product.module';
import { AEProductModule } from './components/ae-product/ae-product.module';
import { SpinnerModule } from './spinner/spinner.module';
import { GotoButtonModule } from './goto-button/goto-button.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NgbModule,
    ProductStoreFilterModule,
    BookmarkedFilterModule,
    AmazonEbayFilterModule,
    PaginationModule,
    PopupModule,
    SpinnerModule,
    InsideSpinnerModule,
    PopupModule,
    EtsyProductModule,
    AEProductModule,
    GotoButtonModule,
  ],
  declarations: [
    CarouselComponent,
  ],

  providers: [BackendService, BookmarkSharedService],

  exports: [
    CarouselComponent,
    ProductStoreFilterModule,
    BookmarkedFilterModule,
    AmazonEbayFilterModule,
    PaginationModule,
    SpinnerModule,
    InsideSpinnerModule,
    PaginationModule,
    EtsyProductModule,
    AEProductModule,
    GotoButtonModule,
  ],

})
export class SharedModule {
}
