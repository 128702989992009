import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

import { ShopifyProductSearchComponent } from './shopify-product-search.component';
import { ShopifyProductSearchService } from './shopify-product-search.service';
import { ShopifyProductModule } from './components/shopify-product/shopify-product.module';
import { SharedModule } from '../../../shared/shared.module';
import {PaginationModule} from '../../../shared/components/pagination/pagination.module';

@NgModule({

  imports: [
    CommonModule,
    RouterModule,
    NgbModule,
    ShopifyProductModule,
    SharedModule,
    PaginationModule
  ],

  declarations: [ShopifyProductSearchComponent],

  providers: [ShopifyProductSearchService],

  exports: [ShopifyProductSearchComponent]
})

export class ShopifyProductSearchModule {
}
