import { Component, Input, ElementRef, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { Chart } from 'chart.js';

@Component({
  selector: 'chart',
  template: '<canvas></canvas>',
  styles: [':host { display: block; }']
})
export class ChartComponent implements OnInit, OnChanges {

  public chart: any;

  @Input() type: string;
  @Input() data: any;
  @Input() options: any;

  constructor(private elementRef: ElementRef) { }

  ngOnInit() {

    const canvas: CanvasRenderingContext2D = this.elementRef.nativeElement.querySelector('canvas');

    this.chart = new Chart(canvas, {
      type: this.type,
      data: this.data,
      options: this.options,
    });

  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.chart && changes['data']) {
      const currentValue = changes['data'].currentValue;
      ['datasets', 'labels', 'xLabels', 'yLabels'].forEach(property => {
        this.chart.data[property] = currentValue[property];
      });
      this.chart.update();
    }
  }
}
