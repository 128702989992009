import { Component, OnInit, Output, EventEmitter, HostListener } from '@angular/core';

@Component({
  selector: 'app-bookmarked-filter',
  templateUrl: './bookmarked-filter.component.html',
  styleUrls: ['./bookmarked-filter.component.scss']
})

export class BookmarkedFilterComponent implements OnInit {
  @Output() filterParams = new EventEmitter<Object>();
  params = {
    category: '',
    keyword: ''
  };
  keyword: string;
  selectActivated = false;
  categoryName = 'Select A Category';
  categories = [
    'No Filter',
    'AMAZON PRODUCTS',
    'EBAY PRODUCTS',
    'SHOPIFY PRODUCTS',
    'SHOPIFY STORES',
    'ALI EXPRESS PRODUCTS',
  ];
  categoryVals = ['', 'amazon', 'ebay', 'shopifyProducts', 'shopifyStores', 'aliExpress'];

  constructor() { }

  ngOnInit() {
    this.keyword = '';
  }

  setCategory(filterName, index) {
    this.categoryName = filterName;
    this.params.category = this.categoryVals[index];
  }

  applyFilters() {
    this.params.keyword = this.keyword;
    this.filterParams.emit(this.params);
  }

  @HostListener('document:click', ['$event'])
  clickout() {
    !(event as any).srcElement.classList.contains('select') ? this.selectActivated = false : null;
  }

}
