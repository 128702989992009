import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { catchError } from 'rxjs/operators';
import { LoggerService } from '../../services/logger.service';
import { AlertService } from 'src/app/services/alert.service';

@Injectable()
export class BackendService {
  lastUsedMethod: string;

  constructor(
    private http: HttpClient,
    private logger: LoggerService,
    private _alert: AlertService
  ) { }

  get(url: string, params?): Observable<any> {
    this.lastUsedMethod = 'get';
    return this.http.get(url, params)
      .pipe(catchError(this.handleError.bind(this)));
  }
  post(url: string, data?) {
    this.lastUsedMethod = 'post';
    return this.http.post(url, data)
      .pipe(catchError(this.handleError.bind(this)));
  }

  delete(url: string, target?) {
    this.lastUsedMethod = 'delete';
    return this.http.delete(url, target)
      .pipe(catchError(this.handleError.bind(this)));
  }

  private handleError(error: HttpErrorResponse) {
    this.logger.error('BackendService', this.lastUsedMethod, error.message, error);

    const message: string = error.status === 429 ?
      'You are making too many requests, try again in a few minutes'
      : 'we\'re sorry the search request failed, please try again';

    this._alert.error(message);

    return of(false);
  }
}
