import { Component, OnInit } from '@angular/core';
import { MembersService } from '../../services/members.service';
import {NgbTypeaheadConfig} from '@ng-bootstrap/ng-bootstrap';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, tap, switchMap } from 'rxjs/operators';


@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.css'],
  providers: [NgbTypeaheadConfig] // add NgbTypeaheadConfig to the component providers
})
export class AnalyticsComponent implements OnInit {

  public domainName: string;
  model: any;
  searching = false;
  searchFailed = false;

  constructor(private _membersService: MembersService, config: NgbTypeaheadConfig) {
    // config.showHint = true;
  }

  public ngOnInit() {

    this.domainName = 'fashionnova.com';

  }

  public formatter = (x: {domainName: string}) => x.domainName;

  public search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(term =>

        this._membersService.storeKeywordSearch(term).pipe(
          tap(() => this.searchFailed = false),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          }))
      ),
      tap(() => this.searching = false)
    )

}
