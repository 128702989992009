import { faGear, faSignInAlt, faTimes, faCheck, faTrash, faEdit, faAt } from '@fortawesome/free-solid-svg-icons';
import { OnInit, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import {
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';

import { AuthService } from '../../services/auth.service';

import { FormValidators } from '../../form-validators';

@Component({
  selector: 'app-password-reset-execute',
  templateUrl: './password-reset-execute.component.html',
  styleUrls: ['./password-reset-execute.component.css']
})
export class PasswordResetExecuteComponent implements OnInit {

  faGear = faGear;
  faSignInAlt = faSignInAlt;
  faTimes = faTimes;
  faCheck = faCheck;
  faTrash = faTrash;
  faEdit = faEdit;
  faAt = faAt;

  public errorMessage: string;
  public resetComplete: boolean;
  public form: FormGroup;
  public isBusy: boolean;

  private _token: string;

  constructor(
    private _route: ActivatedRoute,
    private _authService: AuthService,
  ) {
  }

  public ngOnInit() {

    // Ensure user is logged out
    // this._authService.clearToken();

    this._route.params.subscribe(params => {
      this._token = params['token'];
    });

    this.form = new FormGroup({
      email: new FormControl('', [
        Validators.required,
        Validators.maxLength(500),
        Validators.email,
      ]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(100),
        //  Validators.pattern(PASSWORD_REGEX),
      ]),
      confirmPassword: new FormControl('', [
        Validators.required,
        Validators.maxLength(100),
      ]),
    }, {
      validators: [FormValidators.MatchPassword],
    });
  }

  public async onSubmit() {

    if (!this.form.valid) {
      return;
    }

    this.isBusy = true;
    this.form.disable();

    let result: any;
    try {
      result = await this._authService.passwordResetExecute(
        this.form.value.email,
        this.form.value.password,
        this._token,
      ).toPromise();

      this.resetComplete = true;
    } catch (error) {
      this.errorMessage = 'Reset request has expired, please request a new password reset email';
    }

    this.isBusy = false;
    this.form.enable();


  }


}
