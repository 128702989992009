import { Directive, Input, HostBinding } from '@angular/core';
import { FormControl } from '@angular/forms';

@Directive({ selector: '[appErrorStyler]' })
export class InputErrorStylerDirective {

    @Input()
    public appErrorStyler: FormControl;

    @Input()
    public onChangeMode: boolean;

    @HostBinding('class.is-invalid')
    public get isInvalid(): boolean {
        return this.appErrorStyler.invalid && (this.appErrorStyler.dirty || this.appErrorStyler.touched);
    }

    @HostBinding('class.is-valid')
    public get isValid(): boolean {
        return this.appErrorStyler.valid && (this.appErrorStyler.dirty || this.appErrorStyler.touched);
    }

}
