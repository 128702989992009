import { Injectable } from '@angular/core';

export interface IAppConfig {
    googleRecaptchaSiteKey: string;
    envName: string;
}

@Injectable()
export class AppConfig implements IAppConfig {

    public googleRecaptchaSiteKey: string;
    public envName: string;
    public spressioIframeBaseUrl: string;

    constructor() {
    }

}
