import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IBookmarkView } from '../../../../../../server/src/interfaces/IBookmarkView';
import { BOOKMARK_TYPE } from '../../../../../../server/src/Constants';
import { IProductView } from '../../../../../../server/src/interfaces/IProductView';

@Component({
  selector: 'app-etsy-product',
  templateUrl: './etsy-product.component.html',
  styleUrls: ['./etsy-product.component.scss']
})
export class EtsyProductComponent implements OnInit {
  @Input() products: IProductView[];
  @Output() bookmark = new EventEmitter<object>();
  @Input() bookmarks: IBookmarkView[];
  bookmarkType: string = BOOKMARK_TYPE.ETSY_PRODUCT;

  constructor() { }

  ngOnInit() { }

  checkBookmark(product) {
    if (this.bookmarks.length !== 0) {
      return this.bookmarks.some(item => item.sourceId === product);
    } else {
      return false;
    }
  }

  toggleBookmark(product) {
    this.bookmark.emit({
      item: product,
      type: this.bookmarkType
    });
  }

}
