import { Component } from '@angular/core';

@Component({
    selector: 'app-chrome',
    templateUrl: './chrome-extension.component.html',
    styleUrls: ['./chrome-extension.component.css'],
})

export class ChromeExtensionComponent {
    constructor() { }
}
