import { Component, OnInit, ViewChild } from '@angular/core';

import { ShopifyProductSearchService } from './shopify-product-search.service';
import { IShopifySearchRequest } from '../../../../../../server/src/interfaces/IShopifySearchRequest';
import { IProductView } from '../../../../../../server/src/interfaces/IProductView';
import { AlertService } from '../../../services/alert.service';
import { BookmarkSharedService } from '../../../shared/services/bookmark-shared.service';
import { IBookmarkView } from '../../../../../../server/src/interfaces/IBookmarkView';
import { PaginationComponent } from '../../../shared/components/pagination/pagination.component';

@Component({
  selector: 'app-shopify-product-search',
  templateUrl: './shopify-product-search.component.html',
  styleUrls: ['./shopify-product-search.component.scss']
})
export class ShopifyProductSearchComponent implements OnInit {

  @ViewChild('pagination') pagination: PaginationComponent;

  bookmarks: IBookmarkView[];
  products: IProductView[] = [];
  collectionLength: number;
  curPage = 1;
  q: IShopifySearchRequest = {
    page: this.curPage,
    pageSize: 12,
  };
  spinner = true;

  constructor(
    private shopProdService: ShopifyProductSearchService,
    private _bookmarkService: BookmarkSharedService,
    private _alert: AlertService
  ) { }

  ngOnInit() {

    this.updateBookmarks();
    this.updateCollection(1);
  }

  onFilterUpdate(q: IShopifySearchRequest) {
    this.spinner = true;
    this.q = q;
    this.pagination.setPage(1);
    this.updateCollection(1);
  }

  updateCollection(curPage: number) {
    this.spinner = true;
    this.curPage = curPage;
    this.q.page = this.curPage;
    this.shopProdService.productSearch(this.q).subscribe(response => {
      response ? this.products = response.products : this.showMessage();
      response ? this.collectionLength = response.count : this.showMessage();
      this.spinner = false;
    });
  }

  updateBookmarks() {
    const responseResult = this._bookmarkService.getBookmarks();
    responseResult ? responseResult.subscribe(response => this.bookmarks = response) : this.showMessage();
  }

  toggleBookmark(product) {
    this.checkBookmark(product.item.id) ?
      this._bookmarkService.removeBookmark(this.bookmarks.find(
        bookmark => bookmark.sourceId === product.item.id).id
      ).subscribe(response => {
        !response ? this.showMessage() : this.updateBookmarks();
      }
      ) :
      this._bookmarkService.addBookmark(product.item.id, product.type, product.item).subscribe(response => {
        !response ? this.showMessage() : this.updateBookmarks();
      }
      );
  }

  checkBookmark(product) {
    if (this.bookmarks.length !== 0) {
      return this.bookmarks.some(item => item.sourceId === product);
    } else {
      return false;
    }
  }

  showMessage() {
    this.spinner = false;
    // this._alert.error('we\'re sorry the search request failed, please try again');
  }

}
