import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AliExpressProductComponent } from './aliexpress-product.component';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../../../../../shared/shared.module';
import { BookmarkSharedService } from '../../../../../shared/services/bookmark-shared.service';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NgbModule,
    SharedModule
  ],
  declarations: [AliExpressProductComponent],
  providers: [ BookmarkSharedService],
  exports: [AliExpressProductComponent]
})
export class AliExpressProductModule { }
