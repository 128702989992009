import { OnInit, Component } from '@angular/core';
import { faGear, faSignInAlt, faTimes, faCheck, faTrash, faEdit, faAt } from '@fortawesome/free-solid-svg-icons';
import {
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';
// import { ReCaptchaComponent } from 'angular2-recaptcha/angular2-recaptcha';
import { AppConfig } from '../../app.config';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})

export class LoginComponent implements OnInit {

  faGear = faGear;
  faSignInAlt = faSignInAlt;
  faTimes = faTimes;
  faCheck = faCheck;
  faTrash = faTrash;
  faEdit = faEdit;
  faAt = faAt;
  // @ViewChild(ReCaptchaComponent) captcha: ReCaptchaComponent;

  public googleRecaptchaSiteKey: string;
  public form: FormGroup;
  public errorMessage: string;
  public isBusy: boolean;

  constructor(
    public appConfig: AppConfig,
    private _router: Router,
    private _apiService: AuthService,
  ) {
    this.googleRecaptchaSiteKey = this.appConfig.googleRecaptchaSiteKey;
  }

  public ngOnInit() {
    this.form = new FormGroup({
      password: new FormControl('', [
        Validators.required,
        Validators.maxLength(500),
      ]),
      email: new FormControl('', [
        Validators.required,
        Validators.email,
        Validators.maxLength(500),
      ]),
    });
  }

  public onSubmit() {
    this.isBusy = true;
    this.form.disable();
    // this.captcha.execute();
    this.handleCaptcha(null);
  }

  public handleCaptcha(event) {

    // Get the recaptcha response
    const recaptchaResponse: string = '';// = this.captcha.getResponse().toString();

    // Authenticate via server API
    this._apiService.authenticate(this.form.value.email, this.form.value.password, recaptchaResponse)
      .subscribe(result => {
        // Success - trigger success action
        if (result && result.jwt) {
          // Go to the home page
          this._router.navigate(['/search']);
        } else {
          // Failure!
          this.errorMessage = 'Invalid email or password';
          // this.captcha.reset();
          this.isBusy = false;
          this.form.enable();
          this.form.reset();
        }
      }, ((error) => {
        // Failure!
        this.errorMessage = 'Invalid email or password';
        // this.captcha.reset();
        this.isBusy = false;
        this.form.enable();
      }));
  }
}

