import { Component, OnInit } from '@angular/core';
import { AlertService } from '../../services/alert.service';
import { debounceTime } from 'rxjs/operators';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'alert',
    templateUrl: './alert.component.html',
    styles: ['ngb-alert {position: fixed; top: 50px; right: 50px; width: auto}']
})

export class AlertComponent implements OnInit {
    message: any;

    staticAlertClosed: any;

    constructor(private alertService: AlertService) { }

    ngOnInit() {
        this.alertService.getMessage().subscribe(message => {
            this.message = message;
        });

        this.alertService.getMessage().pipe(
            debounceTime(5000)
        ).subscribe(() => this.message = null);
    }
}
