import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

import { MainComponent } from '../components/layout/main.component';
import { SearchComponent } from '../components/search/search.component';
import { AuthGuard } from '../guards/auth.guard';
import { LoginComponent } from '../components/auth/login.component';
import { AuthAdminGuard } from '../guards/auth.adminGuard';
import { AdminComponent } from '../components/admin/admin.component';
import { PasswordResetRequestComponent } from '../components/auth/password-reset-request.component';
import { PasswordResetExecuteComponent } from '../components/auth/password-reset-execute.component';
import { RegisterExecuteComponent } from '../components/auth/register-execute.component';
import { BonusProductsComponent } from '../components/static/bonus-products.component';
import { VideoTutorialsComponent } from '../components/static/video-tutorials.component';
import { UserGuideComponent } from '../components/static/user-guide.component';
import { ChromeExtensionComponent } from '../components/static/chrome-extension.component';
import { LogoutComponent } from '../components/auth/logout.component';
import { AccountComponent } from '../components/account/account.component';
import { AnalyticsComponent } from '../components/analytics/analytics.component';
import { WoocomComponent } from '../components/training/woocom/woocom.component';
import { DomainInspectorComponent } from '../components/admin/domain-inspector/domain-inspector.component';
import { EcomAcademyComponent } from '../components/training/ecom-academy/ecom-academy.component';

const routes: Routes = [{
  path: '', component: MainComponent, children: [
    { path: 'register/:token', component: RegisterExecuteComponent },
    { path: 'login', component: LoginComponent },
    { path: 'logout', component: LogoutComponent },
    { path: 'account', component: AccountComponent },
    { path: 'password/reset/request', component: PasswordResetRequestComponent },
    { path: 'password/reset/execute/:token', component: PasswordResetExecuteComponent },
    { path: 'search', component: SearchComponent, canActivate: [AuthGuard] },
    { path: 'admin', component: AdminComponent, canActivate: [AuthAdminGuard] },
    { path: 'admin/domain/:id', component: DomainInspectorComponent, canActivate: [AuthAdminGuard] },
    { path: 'bonuses', component: BonusProductsComponent, canActivate: [AuthGuard] },
    { path: 'videotutorials', component: VideoTutorialsComponent, canActivate: [AuthGuard] },
    { path: 'userguide', component: UserGuideComponent },
    { path: 'chromeextension', component: ChromeExtensionComponent, canActivate: [AuthGuard] },
    { path: 'analytics', component: AnalyticsComponent, canActivate: [AuthAdminGuard] },
    { path: 'training/woocom', component: WoocomComponent, canActivate: [AuthGuard] },
    { path: 'training/ecomacademy', component: EcomAcademyComponent, canActivate: [AuthGuard] },
  ]
}];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
