import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IBookmarkView } from '../../../../../../server/src/interfaces/IBookmarkView';
import { BOOKMARK_TYPE } from '../../../../../../server/src/Constants';
import { IProductView } from '../../../../../../server/src/interfaces/IProductView';
import { Utilities } from 'src/utilities';

@Component({
  selector: 'app-ae-product',
  templateUrl: './ae-product.component.html',
  styleUrls: ['./ae-product.component.scss']
})
export class AEProductComponent implements OnInit {
  @Input() products: IProductView[];
  @Output() bookmark = new EventEmitter<object>();
  @Input() bookmarks: IBookmarkView[];
  bookmarkType: string = BOOKMARK_TYPE.ALIEXPRESS_PRODUCT;

  public getAliExpressSearchUrl(q: string) {
    return Utilities.getAliExpressSearchUrl(q);
  }

  constructor() { }

  ngOnInit() { }

  checkBookmark(product) {
    if (this.bookmarks.length !== 0) {
      return this.bookmarks.some(item => item.sourceId === product);
    } else {
      return false;
    }
  }

  toggleBookmark(product) {
    this.bookmark.emit({
      item: product,
      type: this.bookmarkType
    });
  }

}
