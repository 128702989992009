import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AmazonEbayFilterComponent } from './amazon-ebay-filter.component';
import { AmazonEbayFilterService } from './amazon-ebay-filter.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
  ],
  declarations: [AmazonEbayFilterComponent],

  providers: [AmazonEbayFilterService],

  exports: [AmazonEbayFilterComponent]
})
export class AmazonEbayFilterModule { }
