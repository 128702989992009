import { IJsonWebToken } from './interfaces/IJsonWebToken';
import { ACCESS_TOKEN } from './constants';

class Utilities {

    public static async wait(ms: number): Promise<any> {
        return new Promise<void>((resolve, reject) => {
            setTimeout(() => {
                resolve();
            }, ms);
        });
    }

    public static getAliExpressSearchUrl(searchText: string): string {
        // Shorten a string to less than maxLen characters without truncating words.
        let keywords: string;
        const maxLen = 60;
        if (searchText.length <= maxLen) {
            keywords = searchText;
        } else {
            keywords = searchText.substr(0, searchText.lastIndexOf(' ', maxLen));
        }
        return `https://www.aliexpress.com/wholesale?catId=0&SearchText=${keywords}`;
    }

    public static getUserId(): string {
        const jwt: string = localStorage.getItem(ACCESS_TOKEN);
        if (!jwt) {
            return null;
        }
        try {
            const decodedJwt: any = Utilities.decodeJwt(jwt);
            return decodedJwt.sub;
        } catch (error) {
            return null;
        }
    }

    public static decodeJwt(token: string): IJsonWebToken {
        if (!token) {
            return null;
        }
        const parts: string[] = token.split('.');
        if (parts.length !== 3) {
            throw new Error('JWT must have 3 parts');
        }
        const decoded: string = this.base64Decode(parts[1]);
        if (!decoded) {
            throw new Error('Cannot decode the token');
        }
        return JSON.parse(decoded);
    }

    private static base64Decode(base64: string): string {
        let output: string = base64.replace(/-/g, '+').replace(/_/g, '/');
        switch (output.length % 4) {
            case 0: {
                break;
            }
            case 2: {
                output += '==';
                break;
            }
            case 3: {
                output += '=';
                break;
            }
            default: {
                throw new Error('Could not decode Base64 string');
            }
        }
        return decodeURIComponent(window.atob(output));
    }

}

export { Utilities };
