import { faGear, faSignInAlt, faTimes, faCheck, faTrash, faEdit, faAt } from '@fortawesome/free-solid-svg-icons';
import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';

import { IUserView } from '../../../../../server/src/interfaces/IUserView';
import { MembersService } from '../../services/members.service';

@Component({
  selector: 'app-children',
  templateUrl: './children.component.html',
  styleUrls: ['./children.component.css']
})
export class ChildrenComponent implements OnInit {

  public form: FormGroup;
  public me: IUserView;
  public maxChildren: number;
  public isBusy: boolean;
  public isBusyDelete: boolean;
  public enableAddChild: boolean;

  faGear = faGear;
  faSignInAlt = faSignInAlt;
  faTimes = faTimes;
  faCheck = faCheck;
  faTrash = faTrash;
  faEdit = faEdit;
  faAt = faAt;

  constructor(
    private _membersService: MembersService,
  ) {
  }

  public ngOnInit() {
    this.form = new FormGroup({
      firstName: new FormControl('', [
        Validators.required,
        Validators.maxLength(500),
      ]),
      email: new FormControl('', [
        Validators.required,
        Validators.email,
        Validators.maxLength(500),
      ]),
    });
    this.load();
  }

  public onSubmit() {

    if (!this.form.valid) {
      return;
    }

    this.form.disable();
    this.isBusy = true;
    const values: any = this.form.value;

    const firstName: string = values.firstName;
    const email: string = values.email;

    this._membersService.createChild(
      firstName,
      email,
    ).subscribe((addResponse: any) => {
      this.form.enable();
      this.form.setValue({ firstName: '', email: '' });
      this.load();
    },
      (error: any) => {
        this.form.enable();
        this.isBusy = false;
        console.error(error);
      }
    );

  }

  public onDeleteChild(id: string) {
    this.isBusyDelete = true;
    this._membersService.deleteChild(id)
      .subscribe((response) => {
        this.load();
        this.isBusyDelete = false;
      });
  }

  private load() {
    this.isBusy = true;
    this._membersService.getMe()
      .subscribe(
        (response: IUserView) => {
          this.me = response;
          this.isBusy = false;
          this.enableAddChild = this.me.children.length < this.me.maxChildren;
        },
    );
  }

}
