import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BookmarkedFilterComponent } from './bookmarked-filter.component';
import { BookmarkedService } from '../../../../components/search/bookmarked/bookmarked.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
  ],
  declarations: [BookmarkedFilterComponent],

  providers: [BookmarkedService],

  exports: [BookmarkedFilterComponent]
})
export class BookmarkedFilterModule { }
