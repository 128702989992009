import * as _ from 'lodash';

import { Component, OnInit, Input } from '@angular/core';
import { MembersService } from '../../../services/members.service';
import { IDomainCompetitor } from '../../../../../../server/src/interfaces/IDomainCompetitor';
import { IDomainAd } from '../../../../../../server/src/interfaces/IDomainAd';
import { IDomainKeyword } from '../../../../../../server/src/interfaces/IDomainKeyword';

@Component({
  selector: 'app-store-traffic',
  templateUrl: './store-traffic.component.html',
  styleUrls: ['./store-traffic.component.css']
})
export class StoreTrafficComponent implements OnInit {

  @Input()
  public domainName: string;
  @Input()
  public facebookPageUrl: string;
  @Input()
  public facebookPageName: string;
  @Input()
  public tabIndex: number;

  public facebookAdsUrl: string;

  public noData: boolean;
  public isLoading = true;

  public trafficOptions: any;
  public trafficData: any;
  public trafficSourceOptions: any;
  public trafficSourceData: any;

  public totalVisits = '0';
  public strength = '0';
  public averageOrganicRank = '0';

  public competitors: IDomainCompetitor[];
  public ads: IDomainAd[];
  public keywords: IDomainKeyword[];

  public section = 'overview';

  constructor(private _membersService: MembersService) {
  }

  public ngOnInit() {

    const nFormatter = (num) => {
      if (num >= 1000000000) {
        return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G';
      }
      if (num >= 1000000) {
        return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
      }
      if (num >= 1000) {
        return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
      }
      return num;
    };

    this.trafficOptions = {
      title: {
        display: false,
        text: 'Traffic History',
        fontSize: 30,
      },
      legend: {
        display: false,
      },
      responsive: true,
      tooltips: {
        mode: 'index',
        intersect: false,
        callbacks: {
          label: function (tooltipItem, chart) {
            return nFormatter(tooltipItem.yLabel);
          }
        }
      },
      hover: {
        mode: 'nearest',
        intersect: true
      },
      scales: {
        xAxes: [{
          display: true,
          scaleLabel: {
            display: false,
          },
          gridLines: {
            display: false
          }
        }],
        yAxes: [{
          display: true,
          scaleLabel: {
            display: false,
          },
          ticks: {
            maxTicksLimit: 5,
            callback: function (value) {
              return nFormatter(value);
            },
            beginAtZero: true
          }
        }]
      }
    };

    this.trafficSourceOptions = {
      title: {
        display: false,
        text: 'Traffic Source',
        fontSize: 30,
      },
      legend: {
        display: false,
      },
      responsive: true,
      tooltips: {
        mode: 'index',
        intersect: false,
        callbacks: {
          label: function (tooltipItem, chart) {
            return ' ' + Math.round(tooltipItem.yLabel) + '%';
          }
        }
      },
      hover: {
        mode: 'nearest',
        intersect: true
      },
      scales: {
        xAxes: [{
          display: true,
          scaleLabel: {
            display: false,
          },
          gridLines: {
            display: false
          }
        }],
        yAxes: [{
          display: true,
          scaleLabel: {
            display: false,
          },
          ticks: {
            maxTicksLimit: 5,
            callback: function (value) {
              return value + '%';
            },
            beginAtZero: true
          }
        }]
      }
    };

    if (this.facebookPageName) {
      this.facebookAdsUrl = `api/members/facebook/ads?pageName=${this.facebookPageName}`;
    }

    this.load();

    if (this.tabIndex === 2) {
      this.onClickKeywords();
    }
    if (this.tabIndex === 3) {
      this.onClickAds();
    }
  }

  public onClickOverview() {
    this.section = 'overview';
  }

  public onClickCompetitors() {
    this.section = 'competitors';
  }

  public onClickKeywords() {
    this.section = 'keywords';
  }

  public onClickAds() {
    this.section = 'ads';
  }


  private load() {

    this._membersService.getAnalytics(this.domainName)
      .subscribe(
        (data) => {

          if (data.noData) {
            this.noData = true;
            this.isLoading = false;
            return;
          }

          this.competitors = data.competitors;
          this.keywords = data.keywords;
          this.ads = data.ads;

          this.trafficData = {
            labels: data.trafficLabels,
            datasets: [
              {
                backgroundColor: '#24618f',
                borderColor: '#eeeeee',
                data: data.trafficDataSet,
                fill: 'start',
                lineTension: 0,
                pointBackgroundColor: '#ed6329'
              }
            ],
          };

          this.trafficSourceData = {
            labels: data.trafficSourceLabels,
            datasets: [{
              backgroundColor: ['#7abf42', '#2058b9', '#ed6329', '#806bce', '#e93dba'],
              borderColor: '#eeeeee',
              data: data.trafficSourceDataSet,
              fill: 'start',
              lineTension: 0,
            }]
          };

          // Load animated values
          if (data.totalVisits > 30) {
            this.animateValue('totalVisits', 0, data.totalVisits, 1000, function (value) {
              return Math.round(value).toLocaleString();
            });
          } else {
            this.totalVisits = '< 30';
          }

          this.animateValue('strength', 0, data.strength, 1000, function (value) {
            return Math.round(value);
          });

          this.animateValue('averageOrganicRank', 0, data.averageOrganicRank, 500, function (value) {
            return Math.round(value);
          });

          this.isLoading = false;
        },
      );
  }

  private animateValue(stat: string, start: number, end: number, duration: number, formatterFunction?: Function) {

    // assumes integer values for start and end

    const range: number = end - start;
    // no timer shorter than 50ms (not really visible any way)
    const minTimer = 50;
    // calc step time to show all interediate values
    let stepTime: number = Math.abs(Math.floor(duration / range));

    // never go below minTimer
    stepTime = Math.max(stepTime, minTimer);

    // get current time and calculate desired end time
    const startTime: number = new Date().getTime();
    const endTime: number = startTime + duration;

    const timer = setInterval(() => {
      const now: number = new Date().getTime();
      const remaining: number = Math.max((endTime - now) / duration, 0);
      const value: number = end - (remaining * range);
      this[stat] = formatterFunction ? formatterFunction(value) : value;
      if (value >= Math.floor(end)) {
        clearInterval(timer);
        this[stat] = formatterFunction ? formatterFunction(end) : end;
      }
    }, stepTime);

  }

}
