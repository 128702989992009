import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { AmazonProductSearchComponent } from './amazon-product-search.component';
import { AmazonProductSearchService } from './amazon-product-search.service';
import { SharedModule } from '../../../shared/shared.module';
import {AmazonProductModule} from './components/amazon-product/amazon-product.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

@NgModule({

  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    AmazonProductModule,
    NgbModule
  ],

  declarations: [AmazonProductSearchComponent],

  providers: [AmazonProductSearchService],

  exports: [AmazonProductSearchComponent]

})

export class AmazonProductSearchModule {
}
