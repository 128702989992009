import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AEProductComponent } from './ae-product.component';
import {RouterModule} from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    RouterModule
  ],
  declarations: [AEProductComponent],
  exports: [AEProductComponent]
})
export class AEProductModule { }
