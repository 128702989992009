import { Injectable } from '@angular/core';


@Injectable()
export class SearchService {

  constructor() {
  }

}
