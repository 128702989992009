import { Component, OnInit } from '@angular/core';
import { faGear, faSignInAlt, faTimes, faCheck, faTrash, faEdit, faAt } from '@fortawesome/free-solid-svg-icons';

import {
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LoggerService } from '../../../services/logger.service';
import { AdminService } from '../../../services/admin.service';
import { IUserView } from '../../../../../../server/src/interfaces/IUserView';

@Component({
  selector: 'app-user-add-update-dialog',
  templateUrl: './user-add-update-dialog.component.html',
  styleUrls: ['./user-add-update-dialog.component.css']
})
export class UserAddUpdateDialogComponent implements OnInit {

  faGear = faGear;
  faSignInAlt = faSignInAlt;
  faTimes = faTimes;
  faCheck = faCheck;
  faTrash = faTrash;
  faEdit = faEdit;
  faAt = faAt;
  
  public form: FormGroup;
  public updateId: string;
  public heading: string;
  public submitButtonText: string;
  public isBusy: boolean;
  public errorMessage: string;

  constructor(
    private _adminService: AdminService,
    public activeModal: NgbActiveModal,
    private _logger: LoggerService,
  ) {
  }

  public ngOnInit() {

    this.form = new FormGroup({
      accessLevel: new FormControl('', [
        Validators.required,
      ]),
      firstName: new FormControl('', [
        Validators.required,
        Validators.maxLength(500),
      ]),
      lastName: new FormControl('', [
        Validators.required,
        Validators.maxLength(500),
      ]),
      email: new FormControl('', [
        Validators.required,
        Validators.email,
        Validators.maxLength(500),
      ]),
      replacementPassword: new FormControl('', [
        Validators.maxLength(500),
      ]),
    });

    // Load any data before making form available
    if (this.updateId) {

      this.heading = 'UPDATE USER';
      this.submitButtonText = 'UPDATE';

      this._adminService.getUser(this.updateId)
        .subscribe(
          (getUserResponse: IUserView) => {
            this.form.setValue(
              {
                accessLevel: getUserResponse.accessLevel.toString(),
                email: getUserResponse.email,
                firstName: getUserResponse.firstName,
                lastName: getUserResponse.lastName,
                replacementPassword: '',
              });
          },
      );
    } else {
      this.heading = 'REGISTER NEW USER';
      this.submitButtonText = 'REGISTER';
    }

  }

  public onSubmit() {

    if (!this.form.valid) {
      return;
    }

    this.isBusy = true;
    this.form.disable();
    const values: any = this.form.value;

    const user: IUserView = {
      accessLevel: Number(values.accessLevel),
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
      replacementPassword: values.replacementPassword
    };

    // Will either add or update an existing entry
    if (this.updateId) {
      user.id = this.updateId;
      this._adminService.updateUser(user)
        .subscribe((updateResponse: any) => {
          this.activeModal.close(updateResponse.id);
        },
          (error: any) => {
            console.error(error);
            this.isBusy = false;
            this.form.enable();
            this.errorMessage = 'Failed to update user';
          });
    } else {
      this._adminService.addUser(user)
        .subscribe((addResponse: any) => {
          this.activeModal.close(addResponse.id);
        },
          (error: any) => {
            this.isBusy = false;
            this.form.enable();
            console.error(error);
            this.errorMessage = 'Failed to add user';
          }
        );
    }

  }

}
