// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  urls: {
    base: 'http://localhost:4200',
    // base: 'http://localhost:3000',
    ping: '/api/ping',
    security: {
      tokenVerify: '/api/security/token/verify',
      authenticate: '/api/security/authenticate',
      passwordResetRequest: '/api/security/password/reset/request',
      passwordResetExecute: '/api/security/password/reset/execute',
      registrationExecute: '/api/security/registration/execute',
      registrationVerify: '/api/security/registration/verify',
    },
    members: {
      storeSearch: '/api/members/search/stores',
      getStore: '/api/members/store',
      productSearch: '/api/members/search/products',
      ebaySearch: '/api/members/search/ebay',
      amazonSearch: '/api/members/search/amazon',
      getBookmarks: '/api/members/bookmarks',
      createBookmark: '/api/members/bookmark',
      removeBookmark: '/api/members/bookmark/',
      getMe: '/api/members/me',
      createChild: '/api/members/child',
      deleteChild: '/api/members/child/',
      getAnalytics: '/api/members/traffic/overview/',
      storeKeywordSearch: '/api/members/search/storekeyword',
      aliExpressProductsSearch: '/api/members/search/aliexpress',
    }
  }
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
