import { Component, Input, OnInit, Output, EventEmitter, HostListener } from '@angular/core';
import { IShopifySearchRequest } from '../../../../../../../server/src/interfaces/IShopifySearchRequest';

@Component({
  selector: 'app-product-store-filter',
  templateUrl: './product-store-filter.component.html',
  styleUrls: ['./product-store-filter.component.scss']
})
export class ProductStoreFilterComponent implements OnInit {
  @Output() request = new EventEmitter<IShopifySearchRequest>();
  @Input() productFilterActive = false;
  @Input() storeFilterActive = false;
  q: IShopifySearchRequest;

  keyword: string;
  pixelState = false;
  rankingState = false;

  sortByName = 'Traffic';
  sortDirectionName = 'High to Low';

  fbFilterName = 'Facebook Page';
  twFilterName = 'Twitter Profile';
  instaFilterName = 'Instagram Profile';
  trafficFilterName = 'Traffic';
  currencyFilterName = 'Currency';
  minPriceFilterName = 'Min Price';
  maxPriceFilterName = 'Max Price';
  dailySaleFilterName = 'Daily Sales';
  socialVals = [
    {
      dir: '',
      val: 0
    },
    {
      dir: '',
      val: 0
    },
    {
      dir: '>',
      val: 1000
    },
    {
      dir: '>',
      val: 10000
    },
    {
      dir: '>',
      val: 100000
    },
    {
      dir: '>',
      val: 1000000
    },
    {
      dir: '<',
      val: 1000
    },
    {
      dir: '<',
      val: 10000
    },
    {
      dir: '<',
      val: 100000
    },
    {
      dir: '<',
      val: 1000000
    },
    {
      dir: '',
      val: 0
    }
  ];
  priceVals = [0, 0, 5, 10, 30, 60, 100, 150, 300];
  trafficVals = [
    {
      min: null,
      max: null
    },
    {
      min: 0,
      max: 100
    },
    {
      min: 100,
      max: 500
    },
    {
      min: 500,
      max: 1000
    },
    {
      min: 1000,
      max: null
    }
  ];

  sortDirectionSelectActive = false;
  sortBySelectActive = false;
  sortByNames = [
    'Traffic',
    'Price',
    'Store Revenue',
    'Facebook Likes',
    'Twitter Followers',
    'Instagram Followers',
  ];

  sortByValues = [];
  sortDirectionValues = [
    'High to Low',
    'Low to High',
  ];

  filters = {
    fb: {
      active: false,
      names: ['No Filter', 'No Profile', '> 1K Likes', '> 10K Likes', '> 100K Likes', '> 1M Likes',
        '< 1K Likes', '< 10K Likes', '< 100K Likes', '< 1M Likes', 'Any Likes']
    },
    tw: {
      active: false,
      names: ['No Filter', 'No Profile', '> 1K Followers', '> 10K Followers', '> 100K Followers', '> 1M Followers',
        '< 1K Followers', '< 10K Followers', '< 100K Followers', '< 1M Followers', 'Any Followers']
    },
    insta: {
      active: false,
      names: ['No Filter', 'No Profile', 'Has Profile']
    },
    traffic: {
      active: false,
      names: ['Any', 'Less than 100', '100 - 500', '500 - 1000', 'Over 1000']
    },
    currency: {
      active: false,
      names: ['No Filter', 'USD', 'EUR', 'GBP', 'CAD', 'AED', 'AMD', 'ANG',
        'AOA', 'ARS', 'AUD', 'AWG', 'AZN', 'BAM', 'BBD', 'BDT', 'BGN', 'BHD', 'BND',
        'BOB', 'BRL', 'BSD', 'BWP', 'BZD', 'CHF', 'CLP', 'CNY', 'COP', 'CRC', 'CZK',
        'DKK', 'DOP', 'EGP', 'ETB', 'FJD', 'GHS', 'GTQ', 'GYD', 'HKD', 'HNL', 'HRK',
        'HUF', 'IDR', 'ILS', 'INR', 'ISK', 'JEP', 'JMD', 'JOD', 'JPY', 'KES', 'KGS',
        'KRW', 'KWD', 'KYD', 'KZT', 'LBP', 'LKR', 'MAD', 'MDL', 'MKD', 'MMK', 'MOP',
        'MUR', 'MVR', 'MXN', 'MYR', 'MZN', 'NAD', 'NGN', 'NOK', 'NPR', 'NZD', 'OMR',
        'PEN', 'PGK', 'PHP', 'PKR', 'PLN', 'PYG', 'QAR', 'RON', 'RSD', 'RUB', 'RWF',
        'SAR', 'SEK', 'SGD', 'THB', 'TND', 'TRY', 'TTD', 'TWD', 'TZS', 'UAH', 'UGX',
        'UYU', 'VND', 'XCD', 'XOF', 'XPF', 'ZAR', 'ZMW'
      ]
    },
    minPrice: {
      active: false,
      names: ['No Filter', 'Free', '5', '10', '30', '60', '100', '150', '300']
    },
    maxPrice: {
      active: false,
      names: ['No Filter', 'Free', '5', '10', '30', '60', '100', '150', '300']
    },
    dailySale: {
      active: false,
      names: ['Any', 'Less than 100', '100 - 500', '500 - 1000', 'Over 1000']
    }
  };

  constructor() {
  }

  ngOnInit() {
    this.keyword = '';
    this.q = {
      page: 1,
      pageSize: 12
    };

    if (this.storeFilterActive) {
      this.sortByValues = [
        'dailyVisitors',
        'bestSellingPrice', // : 'price',
        'totalDailySalesVisitors',
        'facebookPageLikes',
        'twitterProfileFollowers',
        'instagramProfileFollowers',
      ];
    } else {
      this.sortByValues = [
        'dailyVisitors',
        'price', // : 'price',
        'totalDailySalesVisitors',
        'facebookPageLikes',
        'twitterProfileFollowers',
        'instagramProfileFollowers',
      ];
    }
  }

  applyFilters() {
    this.q.keywords = this.keyword;
    this.request.emit(this.q);
  }

  togglePixel() {
    this.pixelState = !this.pixelState;
    this.q.facebookPixel = this.pixelState;
  }

  toggleRanking() {
    this.rankingState = !this.rankingState;
    this.q.topSellersOnly = this.rankingState;
  }

  setFbFilterName(index) {
    this.fbFilterName = this.filters.fb.names[index];
    if (this.socialVals[index].dir === '>') {
      this.q.facebookProfile !== undefined ? delete this.q.facebookProfile : null;
      this.q.facebookLikesMin = this.socialVals[index].val;
      Object.keys(this.q).indexOf('facebookLikesMax') !== -1 ?
        delete this.q.facebookLikesMax : null;
    } else if (this.socialVals[index].dir === '<') {
      this.q.facebookProfile !== undefined ? delete this.q.facebookProfile : null;
      this.q.facebookLikesMax = this.socialVals[index].val;
      Object.keys(this.q).indexOf('facebookLikesMin') !== -1 ?
        delete this.q.facebookLikesMin : null;
    } else {
      switch (index) {
        case 1:
          this.q.facebookProfile = false;
          Object.keys(this.q).indexOf('facebookLikesMax') !== -1 ? delete this.q.facebookLikesMax : null;
          Object.keys(this.q).indexOf('facebookLikesMin') !== -1 ? delete this.q.facebookLikesMin : null;
          break;

        case 10:
          this.q.facebookProfile = true;
          Object.keys(this.q).indexOf('facebookLikesMax') !== -1 ? delete this.q.facebookLikesMax : null;
          Object.keys(this.q).indexOf('facebookLikesMin') !== -1 ? delete this.q.facebookLikesMin : null;
          break;

        default:
          this.q.facebookProfile !== undefined ? delete this.q.facebookProfile : null;
          Object.keys(this.q).indexOf('facebookLikesMax') !== -1 ? delete this.q.facebookLikesMax : null;
          Object.keys(this.q).indexOf('facebookLikesMin') !== -1 ? delete this.q.facebookLikesMin : null;
          break;
      }
    }
  }

  setTwFilterName(index) {
    this.twFilterName = this.filters.tw.names[index];
    if (this.socialVals[index].dir === '>') {
      this.q.twitterProfile !== undefined ? delete this.q.twitterProfile : null;
      this.q.twitterFollowersMin = this.socialVals[index].val;
      Object.keys(this.q).indexOf('facebookLikesMax') !== -1 ?
        delete this.q.twitterFollowersMax : null;
    } else if (this.socialVals[index].dir === '<') {
      this.q.twitterProfile !== undefined ? delete this.q.twitterProfile : null;
      this.q.twitterFollowersMax = this.socialVals[index].val;
      Object.keys(this.q).indexOf('facebookLikesMin') !== -1 ?
        delete this.q.twitterFollowersMin : null;
    } else {
      switch (index) {
        case 1:
          this.q.twitterProfile = false;
          Object.keys(this.q).indexOf('twitterFollowersMax') !== -1 ? delete this.q.twitterFollowersMax : null;
          Object.keys(this.q).indexOf('twitterFollowersMin') !== -1 ? delete this.q.twitterFollowersMin : null;
          break;

        case 10:
          this.q.twitterProfile = true;
          Object.keys(this.q).indexOf('twitterFollowersMax') !== -1 ? delete this.q.facebookLikesMax : null;
          Object.keys(this.q).indexOf('twitterFollowersMin') !== -1 ? delete this.q.facebookLikesMin : null;
          break;

        default:
          this.q.twitterProfile !== undefined ? delete this.q.twitterProfile : null;
          Object.keys(this.q).indexOf('twitterFollowersMax') !== -1 ? delete this.q.facebookLikesMax : null;
          Object.keys(this.q).indexOf('twitterFollowersMin') !== -1 ? delete this.q.facebookLikesMin : null;
          break;
      }
    }
  }

  setInstaFilterName(index) {
    this.instaFilterName = this.filters.insta.names[index];

    console.log(index);
    switch (index) {

      case 1:
        this.q.instagramProfile = false;
        break;

      case 2:
        this.q.instagramProfile = true;
        break;

      default:
        this.q.instagramProfile !== undefined ? delete this.q.instagramProfile : null;
        break;
    }

  }

  setTrafficFilterName(filterName, index) {
    this.trafficFilterName = filterName;
    if (filterName === this.filters.traffic.names[0]) {
      this.q.dailyTrafficMin ? delete this.q.dailyTrafficMin : null;
      this.q.dailyTrafficMax ? delete this.q.dailyTrafficMax : null;
    } else {
      this.q.dailyTrafficMin = this.trafficVals[index].min;
      this.q.dailyTrafficMax = this.trafficVals[index].max;
    }
  }

  setCurrencyFilterName(filterName) {
    this.currencyFilterName = filterName;
    if (filterName === this.filters.currency.names[0]) {
      this.q.currency ? delete this.q.currency : null;
    } else {
      this.q.currency = filterName;
    }
  }

  setMinPriceFilterName(filterName, index) {
    this.minPriceFilterName = filterName;
    if (filterName === this.filters.minPrice.names[0]) {
      this.q.minPrice ? delete this.q.minPrice : null;
    } else {
      this.q.minPrice = this.priceVals[index];
    }
  }

  setMaxPriceFilterName(filterName, index) {
    this.maxPriceFilterName = filterName;
    if (filterName === this.filters.minPrice.names[0]) {
      this.q.maxPrice ? delete this.q.maxPrice : null;
    } else {
      this.q.maxPrice = this.priceVals[index];
    }
  }

  setDailySaleFilterName(filterName, index) {
    this.dailySaleFilterName = filterName;
    if (filterName === this.filters.traffic.names[0]) {
      this.q.dailySalesMin ? delete this.q.dailySalesMin : null;
      this.q.dailySalesMax ? delete this.q.dailySalesMax : null;
    } else {
      this.q.dailySalesMin = this.trafficVals[index].min;
      this.q.dailySalesMax = this.trafficVals[index].max;
    }
  }

  showFilterOptions(filterName?: string, event?) {
    const keys = Object.keys(this.filters);
    keys.forEach((key) => {
      if (key === filterName) {
        this.filters[key].active = !this.filters[key].active;
        event.stopPropagation();
      } else {
        this.filters[key].active = false;
      }
    });
  }

  // Selected sort option from drop down
  setSortBy(sortName: string, index: number) {
    this.q.sortBy = this.sortByValues[index];
    this.sortByName = sortName;
  }

  // Open the sort options drop down
  showSortOptions(event?) {
    this.sortBySelectActive = !this.sortBySelectActive;
    event.stopPropagation();
  }

  setSortDirection(sortDirectionName: string, index: number) {
    this.q.sortDescending = (index === 0);
    this.sortDirectionName = sortDirectionName;
  }

  // Open the sort options drop down
  showSortDirectionOptions(event?) {
    this.sortDirectionSelectActive = !this.sortDirectionSelectActive;
    event.stopPropagation();
  }

  @HostListener('document:click')
  clickout() {
    const keys = Object.keys(this.filters);
    keys.forEach((key) => {
      this.filters[key].active = false;
    });
    this.sortBySelectActive = false;
    this.sortDirectionSelectActive = false;
  }
}
