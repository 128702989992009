import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PopupComponent} from '../../../../../shared/components/popup/popup.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {IBookmarkView} from '../../../../../../../../server/src/interfaces/IBookmarkView';
import {BOOKMARK_TYPE} from '../../../../../../../../server/src/Constants';
import {IProductView} from '../../../../../../../../server/src/interfaces/IProductView';
import { Utilities } from 'src/utilities';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-shopify-product',
  templateUrl: './shopify-product.component.html',
  styleUrls: ['./shopify-product.component.scss']
})
export class ShopifyProductComponent implements OnInit {
  @Output() bookmark = new EventEmitter<object>();
  @Input() products: IProductView[];
  @Input() bookmarks: IBookmarkView[];
  bookmarkType: string = BOOKMARK_TYPE.SHOPIFY_PRODUCT;

  public getAliExpressSearchUrl(q: string) {
    return Utilities.getAliExpressSearchUrl(q);
  }

  constructor(
    private _modalService: NgbModal,
    public authService: AuthService,
  ) { }

  ngOnInit() {}

  onActivateModal(store) {
    const modalRef = this._modalService.open(PopupComponent);
    modalRef.componentInstance.store = store;
  }

  checkBookmark(product) {
    if (this.bookmarks.length !== 0) {
      return this.bookmarks.some(item => item.sourceId === product);
    } else {
      return false;
    }
  }

  toggleBookmark(product) {
    this.bookmark.emit({
      item: product,
      type: this.bookmarkType
    });
  }
}
