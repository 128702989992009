import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tutorial',
  templateUrl: './video-tutorials.component.html',
  styleUrls: ['./video-tutorials.component.css']
})
export class VideoTutorialsComponent implements OnInit {

  public videoModules: any[] = [
    {
      title: null,
      videos: [
        {
          title: 'Overview Tutorial',
          id: 'NEpY2Ff6BX8',
        },
        {
          title: 'Spressio Quick Start',
          id: 'eBQAmP72wss',
        },
        {
          title: 'Spressio Shopify Import',
          id: '2smXv2rUi6A',
        },
        {
          title: 'Shopify Store Search',
          id: 'ns4925PXJLE',
        },
        {
          title: 'Shopify Product Search',
          id: 'aPN65q7lVuM',
        },
        {
          title: 'Free + Shipping',
          id: 'p1hNXFYTT54',
        },
        {
          title: 'Arbitrage Dropship Guide',
          id: '300905184',
          type: 'vimeo',
        },
        {
          title: 'How to Reset Your Password',
          id: 'z5y12IYaHyo',
        },
      ],
    },
  ];

  ngOnInit() {
  }

}
