import { faGear, faSignInAlt, faTimes, faCheck, faTrash, faEdit, faAt } from '@fortawesome/free-solid-svg-icons';
import {  Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';

import { UserAddUpdateDialogComponent } from './user-add-update-dialog.component';
import { AdminService } from '../../../services/admin.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from '../../../services/alert.service';
import { IUserView } from '../../../../../../server/src/interfaces/IUserView';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {

  faGear = faGear;
  faSignInAlt = faSignInAlt;
  faTimes = faTimes;
  faCheck = faCheck;
  faTrash = faTrash;
  faEdit = faEdit;
  faAt = faAt;

  public form: FormGroup;
  public data: IUserView[];
  public pageNumber = 1;
  public pageSize = 10;
  public userCount = 0;
  public isBusy: boolean;

  constructor(
    private _adminService: AdminService,
    private _modalService: NgbModal,
    private _alertService: AlertService,
  ) {
  }

  public ngOnInit() {
    this.form = new FormGroup({
      filter: new FormControl('', [
        Validators.maxLength(500),
      ]),
    });
    this.loadUsers(1);
  }

  public onSubmit() {
    this.loadUsers(1);
  }

  public onAddUpdateUser(id?: string) {

    const modalRef = this._modalService.open(UserAddUpdateDialogComponent);
    modalRef.componentInstance.updateId = id;
    modalRef.result.then((result: any) => {

      this.loadUsers(1);

    }).catch((error) => {
      console.error(error);
    });
  }

  public onResendRegistrationEmail(id: string) {
    this._adminService.resendRegistrationEmail(id)
      .subscribe((addResponse: any) => {

        this._alertService.success('Re-sent registration email to user');
      },
        (error: any) => {
          this._alertService.error('Failed to resend registration email to user');
        }
      );
  }

  public onPageChange(page: number) {
    this.loadUsers(page);
  }

  public onResetFilter() {
    this.form.setValue({ filter: '' });
    this.loadUsers(1);
  }

  private loadUsers(page: number) {

    this.isBusy = true;
    this.form.disable();

    this._adminService.getUsers(page, this.pageSize, this.form.value.filter)
      .subscribe(
        (response) => {
          this.data = response.rows;
          this.userCount = response.rowCount;

          this.isBusy = false;
          this.form.enable();
        },
    );
  }

}
