import * as _ from 'lodash';
import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AuthService } from 'src/app/services/auth.service';
import { AppConfig } from 'src/app/app.config';

@Component({
  selector: 'app-spressio',
  templateUrl: './spressio.component.html',
  styleUrls: ['./spressio.component.scss']
})
export class SpressioComponent implements OnInit {
  public get iframeStyle(): any {
    return {
      width: '100%',
      height: this.iframeHeight ? `${this.iframeHeight}px` : `60vh`,
      border: 'none',
      minHeight: '100%',
    };
  }

  public iframeUrl: string;

  @ViewChild('iframe') iframe: ElementRef;

  private iframeHeight = 0;

  // This method replicates the infinate scrolling seen inside spressio
  // Due to the iframe, we need to send the scroll trigger event to the component
  // REF: https://stackoverflow.com/questions/22086722/resize-cross-domain-iframe-height
  //
  @HostListener('window:scroll', ['$event'])
  public onWindowScroll() {
    const pos: number = window.innerHeight + window.scrollY;
    const max: number = document.body.scrollHeight;
    if (pos >= max - 100) {
      this.iframe.nativeElement.contentWindow.postMessage('FETCH_PAGE', '*');
    }
  }

  constructor(authService: AuthService, appConfig: AppConfig, private route: ActivatedRoute) {

    // Pass through any other query string parameters that exist, as we could be loading saved searches/alerts etc
    const sinceValue = this.route.snapshot.queryParamMap.get('since');
    const ssidValue = this.route.snapshot.queryParamMap.get('ssid');
    const unsubscribeValue = this.route.snapshot.queryParamMap.get('unsubscribe');
    const u = new URL(appConfig.spressioIframeBaseUrl);
    // Always pass in the token for validation and callback inside spressio
    u.searchParams.append('token', authService.authorizationToken);
    if (sinceValue) {
      u.searchParams.append('since', sinceValue);
    }
    if (ssidValue) {
      u.searchParams.append('ssid', ssidValue);
    }
    if (unsubscribeValue) {
      u.searchParams.append('unsubscribe', unsubscribeValue);
    }
    this.iframeUrl = u.toString();
  }

  ngOnInit() {


    window.addEventListener('message', (event: any) => {

      const command: string = _.get(event.data, 'command');
      if (!command) {
        return;
      }
      const value: any = _.get(event.data, 'value');

      // Listen for message that will provide the required height of the iframe
      if (command === 'iframe_height') {
        if (isNaN(value)) {
          return;
        }
        this.iframeHeight = value;
      }

      // Listen for message to scroll to top
      if (command === 'scroll_top') {
        window.scrollTo(0, 0);
      }

    });
  }

}
