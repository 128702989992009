import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GotoButtonComponent } from './goto-button.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [GotoButtonComponent],
  exports: [GotoButtonComponent]
})
export class GotoButtonModule { }
