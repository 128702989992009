import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';

import { IStoreSearchRequest } from '../../../../server/src/interfaces/IStoreSearchRequest';
import { IStoreSearchResponse } from '../../../../server/src/interfaces/IStoreSearchResponse';
import { IProductSearchRequest } from '../../../../server/src/interfaces/IProductSearchRequest';
import { IProductSearchResponse } from '../../../../server/src/interfaces/IProductSearchResponse';
import { IEbaySearchRequest } from '../../../../server/src/interfaces/IEbaySearchRequest';
import { IAmazonSearchRequest } from '../../../../server/src/interfaces/IAmazonSearchRequest';
import { IStoreView } from '../../../../server/src/interfaces/IStoreView';
import { environment } from '../../environments/environment';
import { IUserView } from '../../../../server/src/interfaces/IUserView';

@Injectable()
export class MembersService {

  constructor(private _apiService: HttpClient) { }

  public getAnalytics(id: string): Observable<any> {
    return this._apiService.get<any>(`${(environment.urls.members as any).getAnalytics}${id}`);
  }

  public getMe(): Observable<IUserView> {
    return this._apiService.get<IUserView>((environment.urls.members as any).getMe);
  }

  public createChild(firstName: string, email: string): Observable<any> {
    return this._apiService.post<any>((environment.urls.members as any).createChild, { firstName, email });
  }

  public deleteChild(id: string): Observable<any> {
    return this._apiService.delete<any>(`${(environment.urls.members as any).deleteChild}${id}`);
  }

  public storeKeywordSearch(keyword: string): Observable<any[]> {

    const request: IStoreSearchRequest = {
      keywords: keyword,
      page: 1,
      pageSize: 5,
    };
    // Serialise the request for get method
    const q: string = JSON.stringify(request);
    return this._apiService.get<any[]>((environment.urls.members as any).storeKeywordSearch, {
      params: new HttpParams().set('keyword', keyword)
    });
  }

  public storeSearch(request: IStoreSearchRequest): Observable<IStoreSearchResponse> {
    // Serialise the request for get method
    const q: string = JSON.stringify(request);
    return this._apiService.get<IStoreSearchResponse>(environment.urls.members.storeSearch, {
      params: new HttpParams().set('q', q)
    });
  }

  public getStore(id: string): Observable<IStoreView> {
    return this._apiService.get<IStoreView>(environment.urls.members.getStore + `/${id}`);
  }

  public productSearch(request: IProductSearchRequest): Observable<IProductSearchResponse> {
    // Serialise the request for get method
    const q: string = JSON.stringify(request);
    return this._apiService.get<IProductSearchResponse>(environment.urls.members.productSearch, {
      params: new HttpParams().set('q', q)
    });
  }

  public ebaySearch(request: IEbaySearchRequest): Observable<IProductSearchResponse> {
    // Serialise the request for get method
    const q: string = JSON.stringify(request);
    return this._apiService.get<IProductSearchResponse>(environment.urls.members.ebaySearch, {
      params: new HttpParams().set('q', q)
    });
  }

  public amazonSearch(request: IAmazonSearchRequest): Observable<IProductSearchResponse> {
    // Serialise the request for get method
    const q: string = JSON.stringify(request);
    return this._apiService.get<IProductSearchResponse>(environment.urls.members.amazonSearch, {
      params: new HttpParams().set('q', q)
    });
  }

}
