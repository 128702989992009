import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { BookmarkedComponent } from './bookmarked.component';
import { BookmarkedService } from './bookmarked.service';
import { SharedModule } from '../../../shared/shared.module';
import { EbayProductModule } from '../ebay-product-search/components/ebay-product/ebay-product.module';
import { ShopifyProductModule } from '../shopify-product-search/components/shopify-product/shopify-product.module';
import { ShopifyStoreModule } from '../shopify-store-search/compnents/shopify-store/shopify-store.module';
import { AmazonProductModule } from '../amazon-product-search/components/amazon-product/amazon-product.module';
import { AEProductModule } from 'src/app/shared/components/ae-product/ae-product.module';
import { EtsyProductModule } from 'src/app/shared/components/etsy-product/etsy-product.module';

@NgModule({

  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    EbayProductModule,
    ShopifyProductModule,
    ShopifyStoreModule,
    AmazonProductModule,
    AEProductModule,
    EtsyProductModule,
  ],

  declarations: [BookmarkedComponent],

  providers: [BookmarkedService],

  exports: [BookmarkedComponent]
})

export class BookmarkedModule {
}
