import {Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit {

  @Input() bestSellingProducts;
  @Output() prodIndex = new EventEmitter<number>();
  productNumber = 0;

  constructor() {
  }

  ngOnInit() {
  }

  previousStore() {
    if (this.productNumber === 0) {
      this.productNumber = this.bestSellingProducts.length - 1;
    } else {
      this.productNumber--;
    }
    this.prodIndex.emit(this.productNumber);
  }

  nextStore() {
    if (this.productNumber === this.bestSellingProducts.length - 1) {
      this.productNumber = 0;
    } else {
      this.productNumber++;
    }
    this.prodIndex.emit(this.productNumber);
  }
}
