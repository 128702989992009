import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ShopifyStoreSearchService } from '../../../components/search/shopify-store-search/shopify-store-search.service';
import { IStoreView } from '../../../../../../server/src/interfaces/IStoreView';
import { IGetStoreResponse } from '../../../../../../server/src/interfaces/IGetStoreResponse';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})
export class PopupComponent implements OnInit {

  @Input() store: IStoreView;
  @Input() tabIndex = 0;
  @Input() subTabIndex = 0;
  tabsName = ['store info', 'social info', 'product insights', 'installed apps', 'traffic analysis'];
  activeTab = this.tabsName[0];
  activeButtonIndex = 0;

  constructor(
    public activeModal: NgbActiveModal,
    private shopStoreSearchService: ShopifyStoreSearchService
  ) { }

  ngOnInit() {
    this.shopStoreSearchService.storeIdSearch(this.store.id).subscribe((response: IGetStoreResponse) => {
      response.store ? this.store = response.store : console.log('not found');

      // Caller can specify which tab to display first
      this.tabChange(this.tabIndex);
    });
  }

  tabChange(index) {
    this.activeTab = this.tabsName[index];
    this.activeButtonIndex = index;
  }

}
