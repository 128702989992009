import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-inside-spinner',
  templateUrl: './inside-spinner.component.html',
  styleUrls: ['./inside-spinner.component.scss']
})
export class InsideSpinnerComponent implements OnInit {
  @Input() spinner: boolean;

  constructor() { }

  ngOnInit() {
  }

}
