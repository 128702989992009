import { Component } from '@angular/core';

@Component({
    selector: 'app-guide',
    templateUrl: './user-guide.component.html',
    styleUrls: ['./user-guide.component.css'],
})

export class UserGuideComponent {
    constructor() { }
}
