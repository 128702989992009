import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ProductStoreFilterComponent } from './product-store-filter.component';
import { ProductStoreFilterService } from './product-store-filter.service';
import { SpinnerModule } from '../../../spinner/spinner.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NgbModule,
    SpinnerModule,
    NgbModule,
  ],
  declarations: [ProductStoreFilterComponent],

  providers: [ProductStoreFilterService],

  exports: [ProductStoreFilterComponent]

})
export class ProductStoreFilterModule {
}
