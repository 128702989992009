import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';

import { ShopifyProductService } from './shopify-product.service';
import { ShopifyProductComponent } from './shopify-product.component';
import { SharedModule } from '../../../../../shared/shared.module';
import { BookmarkSharedService } from '../../../../../shared/services/bookmark-shared.service';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NgbModule,
    SharedModule
  ],
  declarations: [ShopifyProductComponent],

  providers: [ShopifyProductService, BookmarkSharedService],

  exports: [ShopifyProductComponent]
})
export class ShopifyProductModule {
}
