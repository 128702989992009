import { Injectable } from '@angular/core';
import {IProductSearchResponse} from '../../../../../../../../server/src/interfaces/IProductSearchResponse';
import {HttpParams} from '@angular/common/http';
import {IProductSearchRequest} from '../../../../../../../../server/src/interfaces/IProductSearchRequest';
import {environment} from '../../../../../../environments/environment';
import {BackendService} from '../../../../../shared/services/backend.service';
import {Observable} from 'rxjs';

@Injectable()
export class ShopifyProductService {

  constructor(private backendService: BackendService) { }

  productSearch(request: IProductSearchRequest): Observable<IProductSearchResponse> {
    const q: string = JSON.stringify(request);
    const url: string = environment.urls.members.productSearch;
    return this.backendService
      .get(url, {params: new HttpParams().set('q', q)})
      .pipe(resp => resp);
  }

}
