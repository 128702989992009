import {Component, OnInit, Input, EventEmitter, Output} from '@angular/core';
import {IBookmarkView} from '../../../../../../../../server/src/interfaces/IBookmarkView';
import {BOOKMARK_TYPE} from '../../../../../../../../server/src/Constants';

@Component({
  selector: 'app-ebay-product',
  templateUrl: './ebay-product.component.html',
  styleUrls: ['./ebay-product.component.scss']
})
export class EbayProductComponent implements OnInit {
  @Input()products;
  @Output() bookmark = new EventEmitter<object>();
  @Input() bookmarks: IBookmarkView[];
  bookmarkType: string = BOOKMARK_TYPE.EBAY_PRODUCT;

  constructor() { }

  ngOnInit() {}

  toggleBookmark(product) {
    this.bookmark.emit({
      item: product,
      type: this.bookmarkType
    });
  }

  checkBookmark(product) {
    if (this.bookmarks.length !== 0) {
      return this.bookmarks.some(item => item.sourceId === product);
    } else {
      return false;
    }
  }

}
