import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-popup-store-info',
  templateUrl: './popup-store-info.component.html',
  styleUrls: ['./popup-store-info.component.scss']
})
export class PopupStoreInfoComponent implements OnInit {
  @Input() store;

  constructor() { }

  ngOnInit() {
  }

  countDays(lastUpdate) {
    const curDate = Date.now();
    const lastUpdateMS = Date.parse(lastUpdate);
    return Math.ceil(Math.abs(curDate - lastUpdateMS) / (1000 * 3600 * 24));
  }
}
