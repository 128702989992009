import { AppConfig } from '../app.config';

import { Injectable } from '@angular/core';

@Injectable()
export class LoggerService {

    constructor(
        private _appConfig: AppConfig,
    ) {
    }

    public debug(className: string, method: string, message, meta?: any): void {
        if (this._appConfig.envName !== 'prod') {
            // tslint:disable-next-line:no-console
            console.log(`DEBUG: ${className}`, method, message, JSON.stringify(meta, null, 4) || '');
        }
    }

    public info(className: string, method: string, message, meta?: any): void {
        // tslint:disable-next-line:no-console
        console.log(`INFO: ${className}`, method, message, JSON.stringify(meta, null, 4) || '');
    }

    public warn(className: string, method: string, message, meta?: any): void {
        console.warn(`WARN: ${className}`, method, message, JSON.stringify(meta, null, 4) || '');
    }

    public error(className: string, method: string, message, meta: any): void {
        let metaString: string;
        try {
            metaString = JSON.stringify(meta, null, 4);
        } catch (error) {
            // Nothing
        }
        console.error(`ERROR: ${className}`, method, message, metaString || '');
    }

}
