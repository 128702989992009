import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-training',
  templateUrl: './training.component.html',
  styleUrls: ['./training.component.css']
})
export class TrainingComponent implements OnInit {

  @Input()
  public videoModules: any[];
  @Input()
  public title: string;

  public selectedModule: any;
  public selectedVideo: any;
  public videoUrl: SafeResourceUrl;

  constructor(private _sanitizer: DomSanitizer, ) { }

  ngOnInit() {
    this.loadModule(this.videoModules[0]);
  }

  public loadVideo(video: any) {
    this.selectedVideo = video;
    this.videoUrl = this.getVideoUrl();
  }

  public loadModule(videoModule: any) {
    this.selectedModule = videoModule;
    this.loadVideo(this.selectedModule.videos[0]);
  }

  private getVideoUrl(): SafeResourceUrl {
    let url: string;
    if (this.selectedVideo.type === 'vimeo') {
      url = `https://player.vimeo.com/video/${this.selectedVideo.id}`;
    } else {
      url = `https://www.youtube.com/embed/${this.selectedVideo.id}?rel=0&showinfo=0&modestbranding=1`;
    }
    return this._sanitizer.bypassSecurityTrustResourceUrl(url);
  }

}
