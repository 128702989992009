import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { SearchService } from './search.service';
import { SearchComponent } from './search.component';
import { ShopifyStoreSearchModule } from './shopify-store-search/shopify-store-search.module';
import { AmazonProductSearchModule } from './amazon-product-search/amazon-product-search.module';
import { BookmarkedModule } from './bookmarked/bookmarked.module';
import { EbayProductSearchModule } from './ebay-product-search/ebay-product-search.module';
import { ShopifyProductSearchModule } from './shopify-product-search/shopify-product-search.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DropshipProductSearchModule } from './dropship-products/dropship-product-search.module';
import { SpressioModule } from './spressio/spressio.module';

@NgModule({

  imports: [
    CommonModule,
    RouterModule,
    ShopifyStoreSearchModule,
    AmazonProductSearchModule,
    BookmarkedModule,
    EbayProductSearchModule,
    ShopifyProductSearchModule,
    DropshipProductSearchModule,
    NgbModule,
    SpressioModule,
  ],

  declarations: [SearchComponent],

  providers: [SearchService]
})

export class SearchModule {
}
