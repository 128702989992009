import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InsideSpinnerComponent } from './inside-spinner.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [InsideSpinnerComponent],
  exports: [InsideSpinnerComponent]
})
export class InsideSpinnerModule { }
