import * as _ from 'lodash';

import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';

import { AuthService } from '../services/auth.service';


@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private _router: Router, private auth: AuthService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        if (_.isEmpty(this.auth.authorizationToken)) {
            this._router.navigate(['/login']);
        }
        return of(true);
    }

}
