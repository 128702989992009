import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-woocom',
  templateUrl: './woocom.component.html',
  styleUrls: ['./woocom.component.css']
})
export class WoocomComponent implements OnInit {

  public videoModules: any[] = [
    {
      title: null,
      videos: [
        {
          title: '1. Setting up Bluehost',
          id: 'eVV7I0BX2vA',
        },
        {
          title: '2. Installing Wordpress',
          id: 'lxw2gKR1xK4',
        },
        {
          title: '3. Wordpress Overview',
          id: 'Uc4qFVocwP8',
        },
        {
          title: '4. Installing WooCommerce',
          id: 'W-nECwRcC2w',
        },
        {
          title: '5. WooCommerce Overview',
          id: '4r1FxN7z7Tw',
        },
        {
          title: '6. Setting up Your Theme',
          id: 'j9iMrzxLNDo',
        },
        {
          title: '7. Installing Ali Dropship Plugin',
          id: 'lM3o2YVwEoQ',
        },
        {
          title: '8. Researching Top Products',
          id: 'OXDOW_ZRiKk',
        },
        {
          title: '9. Importing Products',
          id: 'cLPjZHSbc5E',
        },
        {
          title: '10. Fulfilling an Order',
          id: 'ZlsWqDAt6Ok',
        },
        {
          title: '11. Integrating with Facebook Catalog',
          id: '1Az2Sg077A0',
        },
        {
          title: '12. Setting up Facebook Ads',
          id: '1EbB769-U1Q',
        },
        {
          title: '13. Getting Instagram Traffic',
          id: 'A-ThwVS1pDw',
        },
      ],
    },
  ];

  ngOnInit() {
  }

}
