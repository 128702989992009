import { Component, OnInit } from '@angular/core';
import { IBookmarkView } from '../../../../../../server/src/interfaces/IBookmarkView';
import { IProductView } from '../../../../../../server/src/interfaces/IProductView';
import { IStoreView } from '../../../../../../server/src/interfaces/IStoreView';
import { IAmazonSearchRequest } from '../../../../../../server/src/interfaces/IAmazonSearchRequest';
import { IEbaySearchRequest } from '../../../../../../server/src/interfaces/IEbaySearchRequest';
import { IShopifySearchRequest } from '../../../../../../server/src/interfaces/IShopifySearchRequest';
import { AlertService } from '../../../services/alert.service';
import { BOOKMARK_TYPE } from '../../../../../../server/src/Constants';
import { BookmarkSharedService } from '../../../shared/services/bookmark-shared.service';
import { IHotProductsSearchRequest } from '../../../../../../server/src/interfaces/IHotProductsSearchRequest';

@Component({
  selector: 'app-bookmarked',
  templateUrl: './bookmarked.component.html',
  styleUrls: ['./bookmarked.component.scss']
})
export class BookmarkedComponent implements OnInit {
  bookmarks: IBookmarkView[] = [];
  selectedCategory = '';
  amazonBookmarks: IProductView[] = [];
  ebayBookmarks: IProductView[] = [];
  shopifyProductBookmarks: IProductView[] = [];
  shopifyStoreBookmarks: IStoreView[] = [];
  aliExpressProductBookmarks: IProductView[] = [];
  etsyProductBookmarks: IProductView[] = [];
  spinner = true;

  amazonQ: IAmazonSearchRequest = {
    page: 1,
    pageSize: Infinity,
    keywords: ''
  };

  hotQ: IHotProductsSearchRequest = {
    page: 1,
    pageSize: Infinity,
    keywords: ''
  };

  ebayQ: IEbaySearchRequest = {
    page: 1,
    pageSize: Infinity,
    keywords: ''
  };

  shopifyProductQ: IShopifySearchRequest = {
    page: 1,
    pageSize: Infinity,
  };

  shopifyStoreQ: IShopifySearchRequest = {
    page: 1,
    pageSize: Infinity,
  };

  constructor(
    private _bookmarkedService: BookmarkSharedService,
    private _alert: AlertService
  ) { }

  ngOnInit() {
    this.updateCollection();
  }

  updateCollection(keywords?) {
    this.spinner = true;
    this._bookmarkedService.getBookmarks(keywords).subscribe(response => {
      response ? this.bookmarks = response : this.showMessage();

      this.amazonBookmarks = [];
      response.reduce((pVal, nVal): any => {
        nVal.sourceType === BOOKMARK_TYPE.AMAZON_PRODUCT ? this.amazonBookmarks.push(nVal.item) : null;
      }, this.amazonBookmarks);

      this.ebayBookmarks = [];
      response.reduce((pVal, nVal): any => {
        nVal.sourceType === BOOKMARK_TYPE.EBAY_PRODUCT ? this.ebayBookmarks.push(nVal.item) : null;
      }, this.ebayBookmarks);

      this.shopifyStoreBookmarks = [];
      response.reduce((pVal, nVal): any => {
        nVal.sourceType === BOOKMARK_TYPE.SHOPIFY_STORE ? this.shopifyStoreBookmarks.push(nVal.item) : null;
      }, this.shopifyStoreBookmarks);

      this.shopifyProductBookmarks = [];
      response.reduce((pVal, nVal): any => {
        nVal.sourceType === BOOKMARK_TYPE.SHOPIFY_PRODUCT ? this.shopifyProductBookmarks.push(nVal.item) : null;
      }, this.shopifyProductBookmarks);

      this.aliExpressProductBookmarks = [];
      response.reduce((pVal, nVal): any => {
        nVal.sourceType === BOOKMARK_TYPE.ALIEXPRESS_PRODUCT ? this.aliExpressProductBookmarks.push(nVal.item) : null;
      }, this.aliExpressProductBookmarks);

      this.etsyProductBookmarks = [];
      response.reduce((pVal, nVal): any => {
        nVal.sourceType === BOOKMARK_TYPE.ETSY_PRODUCT ? this.etsyProductBookmarks.push(nVal.item) : null;
      }, this.etsyProductBookmarks);

      this.spinner = false;
    });
  }

  onFiltersUpdate(params) {
    this.selectedCategory = params.category;
    this.updateCollection(params.keyword);
  }

  checkVal(val: string) {
    return this.selectedCategory === '' || this.selectedCategory === val;
  }

  updateBookmarks() {
    this._bookmarkedService.getBookmarks().subscribe(response => {
      response ? this.bookmarks = response : this.showMessage();
    });
  }

  toggleBookmark(product) {
    this.checkBookmark(product.item.id) ?
      this._bookmarkedService.removeBookmark(this.bookmarks.find(
        bookmark => bookmark.sourceId === product.item.id).id
      ).subscribe(response => {
        !response ? this.showMessage() : this.updateCollection();
      }
      ) :
      this._bookmarkedService.addBookmark(product.item.id, product.type, product.item).subscribe(response => {
        !response ? this.showMessage() : this.updateCollection();
      }
      );
  }

  checkBookmark(store) {
    if (this.bookmarks.length !== 0) {
      return this.bookmarks.some(item => item.sourceId === store);
    } else {
      return false;
    }
  }

  showMessage() {
    this.spinner = false;
    // this._alert.error('we\'re sorry the search request failed, please try again');
  }
}
