import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularTwitterTimelineModule } from "angular-twitter-timeline";
import { NgxPageScrollModule } from 'ngx-page-scroll';

import { AppComponent } from './app.component';
import { RegisterExecuteComponent } from './components/auth/register-execute.component';
import { AuthGuard } from './guards/auth.guard';
import { PasswordResetRequestComponent } from './components/auth/password-reset-request.component';
import { PasswordResetExecuteComponent } from './components/auth/password-reset-execute.component';
import { LoginComponent } from './components/auth/login.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthService } from './services/auth.service';
import { httpInterceptorProviders } from './http-interceptors';
import { AppConfig } from './app.config';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AppInitialiser } from './app.initialiser';
import { LoggerService } from './services/logger.service';
import { MembersService } from './services/members.service';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { InputErrorStylerDirective } from './directives/inputErrorStyler.directive';
import { AlertService } from './services/alert.service';
import { AlertComponent } from './components/alert/alert.component';

import { AdminService } from './services/admin.service';
import { UserAddUpdateDialogComponent } from './components/admin/users/user-add-update-dialog.component';
import { AdminComponent } from './components/admin/admin.component';
import { UsersComponent } from './components/admin/users/users.component';
import { AuthAdminGuard } from './guards/auth.adminGuard';
import { AppRoutingModule } from './app-routing/app-routing.module';
import { MainComponent } from './components/layout/main.component';
import { SearchModule } from './components/search/search.module';
import { LayoutModule } from './components/layout/layout.module';
import { SharedModule } from './shared/shared.module';
import { FacebookModule } from 'ngx-facebook';
import { BonusProductsComponent } from './components/static/bonus-products.component';
import { VideoTutorialsComponent } from './components/static/video-tutorials.component';
import { UserGuideComponent } from './components/static/user-guide.component';
import { ChromeExtensionComponent } from './components/static/chrome-extension.component';
import { LogoutComponent } from './components/auth/logout.component';
import { AccessLevelPipe } from './pipes/access-level.pipe';
import { AccountComponent } from './components/account/account.component';
import { ChildrenComponent } from './components/account/children.component';
import { AnalyticsComponent } from './components/analytics/analytics.component';
import { StoreAnalyticsModule } from './shared/components/store-analytics/store-analytics.module';
import { DomainInspectorComponent } from './components/admin/domain-inspector/domain-inspector.component';
import { TrainingComponent } from './components/training/training.component';
import { WoocomComponent } from './components/training/woocom/woocom.component';
import { EcomAcademyComponent } from './components/training/ecom-academy/ecom-academy.component';
import { ChartsModule } from 'ng2-charts';

@NgModule({
    declarations: [
        AppComponent,
        RegisterExecuteComponent,
        PasswordResetExecuteComponent,
        PasswordResetRequestComponent,
        LoginComponent,
        InputErrorStylerDirective,
        AlertComponent,
        AdminComponent,
        UserAddUpdateDialogComponent,
        UsersComponent,
        MainComponent,
        BonusProductsComponent,
        VideoTutorialsComponent,
        UserGuideComponent,
        ChromeExtensionComponent,
        LogoutComponent,
        AccessLevelPipe,
        AccountComponent,
        ChildrenComponent,
        AnalyticsComponent,
        DomainInspectorComponent,
        TrainingComponent,
        WoocomComponent,
        EcomAcademyComponent,
    ],
    imports: [
        HttpClientModule,
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        // ReCaptchaModule,
        NgbModule,
        FacebookModule.forRoot(),
        AppRoutingModule,
        FontAwesomeModule,
        SearchModule,
        LayoutModule,
        SharedModule,
        NgxPageScrollModule,
        StoreAnalyticsModule,
        BrowserAnimationsModule,
        ChartsModule,
        AngularTwitterTimelineModule,
    ],
    providers: [
        HttpClient,
        AppConfig,
        AppInitialiser,
        {
            provide: APP_INITIALIZER,
            useFactory: (appInitialiser: AppInitialiser) => () => appInitialiser.load(), deps: [AppInitialiser],
            multi: true
        },
        AuthService,
        httpInterceptorProviders,
        AuthGuard,
        AuthAdminGuard,
        LoggerService,
        MembersService,
        AlertService,
        AdminService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
