import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { SharedModule } from '../../../shared/shared.module';

import { PaginationModule } from '../../../shared/components/pagination/pagination.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DropshipProductSearchComponent } from './dropship-product-search.component';
import { AliExpressProductSearchService } from './aliexpress-product-search.service';
import { AliExpressProductModule } from './components/aliexpress-product/aliexpress-product.module';
import { DropshipFilterComponent } from './dropship-filter.component';
import { EbayProductComponent } from './components/ebay-product/ebay-product.component';
import { AmazonProductComponent } from './components/amazon-product/amazon-product.component';
import { EbayProductSearchService } from '../ebay-product-search/ebay-product-search.service';
import { AmazonProductSearchService } from '../amazon-product-search/amazon-product-search.service';
import { AliExpressProductComponent } from './components/aliexpress-product/aliexpress-product.component';
import { FormsModule } from '@angular/forms';

@NgModule({

  imports: [
    FormsModule,
    CommonModule,
    RouterModule,
    NgbModule,
    SharedModule,
    AliExpressProductModule,
    PaginationModule
  ],

  declarations: [
    DropshipProductSearchComponent,
    DropshipFilterComponent,
    AmazonProductComponent,
    EbayProductComponent,
  ],

  providers: [
    AliExpressProductSearchService,
    EbayProductSearchService,
    AmazonProductSearchService,
  ],

  exports: [DropshipProductSearchComponent, AliExpressProductModule, AliExpressProductComponent]
})

export class DropshipProductSearchModule {
}
