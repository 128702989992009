import { Injectable } from '@angular/core';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';
import {IProductSearchResponse} from '../../../../../../server/src/interfaces/IProductSearchResponse';
import {BackendService} from '../../../shared/services/backend.service';
import {HttpParams} from '@angular/common/http';
import { IProductSearchRequest } from '../../../../../../server/src/interfaces/IProductSearchRequest';

@Injectable()
export class AmazonProductSearchService {

  constructor(
    private _backEndService: BackendService
  ) {}

  public productSearch(request: IProductSearchRequest): Observable<IProductSearchResponse> {
    const q: string = JSON.stringify(request);
    const url: string = environment.urls.members.amazonSearch;
    return this._backEndService
      .get(url, {params: new HttpParams().set('q', q)});
  }
}
