import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AmazonProductComponent } from './amazon-product.component';
import {RouterModule} from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    RouterModule
  ],
  declarations: [AmazonProductComponent],
  exports: [AmazonProductComponent]
})
export class AmazonProductModule { }
