import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { EbayProductSearchService } from './ebay-product-search.service';
import { EbayProductSearchComponent } from './ebay-product-search.component';
import { SharedModule } from '../../../shared/shared.module';
import {PaginationModule} from '../../../shared/components/pagination/pagination.module';
import {EbayProductModule} from './components/ebay-product/ebay-product.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

@NgModule({

  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    PaginationModule,
    EbayProductModule,
    NgbModule
  ],

  declarations: [EbayProductSearchComponent],

  providers: [EbayProductSearchService],

  exports: [EbayProductSearchComponent]
})

export class EbayProductSearchModule {
}
