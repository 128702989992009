import * as _ from 'lodash';

import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AlertService } from '../../../services/alert.service';
import { BookmarkSharedService } from '../../../shared/services/bookmark-shared.service';
import { IBookmarkView } from '../../../../../../server/src/interfaces/IBookmarkView';
import { IProductView } from '../../../../../../server/src/interfaces/IProductView';
import { AliExpressProductSearchService } from './aliexpress-product-search.service';
import { AmazonProductSearchService } from '../amazon-product-search/amazon-product-search.service';
import { EbayProductSearchService } from '../ebay-product-search/ebay-product-search.service';
import { IProductSearchRequest } from '../../../../../../server/src/interfaces/IProductSearchRequest';

@Component({
  selector: 'app-dropship-product-search',
  templateUrl: './dropship-product-search.component.html',
  styleUrls: ['./dropship-product-search.component.scss']
})
export class DropshipProductSearchComponent implements OnInit {

  @ViewChild('results') public resultsDiv: ElementRef;

  public bookmarks: IBookmarkView[];

  public productsAliExpress: IProductView[] = [];
  public productsAmazon: IProductView[] = [];
  public productsEbay: IProductView[] = [];

  public filteredProductsAmazon: IProductView[] = [];
  public filteredProductsEbay: IProductView[] = [];
  public presetFilterKeyword: string;

  public searchRequested = false;
  public curPage = 1;
  public spinnerAliExpress = false;
  public spinnerAmazon = false;
  public spinnerEbay = false;
  public q: IProductSearchRequest;
  public expandedProduct: IProductView;
  public expandedSource: string;
  public showBackButton: boolean;

  private _preExpandedKeyword: string;

  constructor(
    private _aliExpressSearchService: AliExpressProductSearchService,
    private _amazonSearchService: AmazonProductSearchService,
    private _ebaySearchService: EbayProductSearchService,
    private _bookmarkService: BookmarkSharedService,
    private _alert: AlertService
  ) { }

  public ngOnInit() {

    this.q = {
      page: 1,
      pageSize: 100,
 //     keywords: "sunglasses",
    };
/*
    this.searchRequested = true;
    this.updateBookmarks();
    this.updateCollection(1);
*/
  }

  public onBackSearchRequest(data?: any) {
    this.showBackButton = false;
    this.presetFilterKeyword = this._preExpandedKeyword;
    this.q.keywords = this._preExpandedKeyword;
    this.onFilterUpdate(this.q);
  }

  public onFilterUpdate(q: IProductSearchRequest) {

    this._preExpandedKeyword = q.keywords;

    this.expandedProduct = null;
    this.expandedSource = null;
    this.searchRequested = true;
    this.q = q;
    this.updateCollection(1);
  }

  public productSelected(product: IProductView) {
   // this.expandedProduct = product;
   // this.processPricing();
  }

  public productExpanded(product: IProductView, source: string) {

    this.expandedProduct = product;
    this.expandedSource = source;

    this.presetFilterKeyword = product.title;
    this.q.keywords = product.title;

    this.showBackButton = true;

    this.updateCollection(1);
  }

  public updateCollection(curPage: number) {


    this.curPage = curPage;

    if (!this.expandedSource) {
      this.spinnerAliExpress = true;
      this.productsAliExpress = [];
      this._aliExpressSearchService.productSearch(this.q).subscribe(response => {

        // this.productsAliExpress = _.sortBy(response.products, 'price');

        this.productsAliExpress = response.products;
        this.spinnerAliExpress = false;

        setTimeout(() => {
          this.resultsDiv.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }, 1000);
      });
    }



    if (this.expandedSource !== 'amazon') {
      this.spinnerAmazon = true;

      this.productsAmazon = [];

      // Map q.category to amazon category
      const mapAliExpressToAmazonCategory: any = {
        '': 'aps',
        '3': 'aps',
        '34': 'automotive-intl-ship',
        '66': 'beauty-intl-ship',
        '200004360': 'aps',
        '7': 'kitchen-intl-ship',
        '13': 'tools-intl-ship',
        '44': 'electronics-intl-ship',
        '5': 'electronics-intl-ship',
        '502': 'electronics-intl-ship',
        '2': 'kitchen-intl-ship',
        '1503': 'sporting-intl-ship',
        '200003655': 'beauty-intl-ship',
        '42': 'computers-intl-ship',
        '15': 'hpc-intl-ship',
        '6': 'electronics-intl-shi',
        '200001996': 'aps',
        '36': 'beauty-intl-ship',
        '39': 'electronics-intl-ship',
        '1524': 'luggage-intl-ship',
        '1501': 'baby-products-intl-ship',
        '21': 'kitchen-intl-ship',
        '509': 'electronics-intl-ship',
        '30': 'aps',
        '322': 'shoes-intl-ship',
        '200001075': 'aps',
        '18': 'sporting-intl-ship',
        '1420': 'tools-intl-ship',
        '26': 'toys-and-games-intl-ship',
        '200003498': 'aps',
        '1511': 'aps',
        '320': 'aps',
      };

      const amazonProductSearch: IProductSearchRequest = {
        category: mapAliExpressToAmazonCategory[this.q.category] || 'aps',
        maxPrice: this.q.maxPrice,
        minPrice: this.q.minPrice,
        keywords: this.q.keywords,
        page: this.q.page,
        pageSize: this.q.pageSize,
      };

      this._amazonSearchService.productSearch(amazonProductSearch).subscribe(response => {

        response ? this.productsAmazon = response.products : this.showMessage();
        this.spinnerAmazon = false;

        this.processPricing();
      });
    }

    if (this.expandedSource !== 'ebay') {
      this.spinnerEbay = true;
      this.productsEbay = [];

      // Map q.category to ebay category
      const mapAliExpressToEbayCategory: any = {
        '': '',
        '3': '11450',
        '34': '',
        '66': '26395',
        '200004360': '',
        '7': '58058',
        '13': '11700',
        '44': '293',
        '5': '293',
        '502': '293',
        '2': '',
        '1503': '11700',
        '200003655': '26395',
        '42': '58058',
        '15': '11700',
        '6': '293',
        '200001996': '12576',
        '36': '281',
        '39': '11700',
        '1524': '3252',
        '1501': '2984',
        '21': '12576',
        '509': '15032',
        '30': '625',
        '322': '11450',
        '200001075': '',
        '18': '888',
        '1420': '11700',
        '26': '220',
        '200003498': '3252',
        '1511': '281',
        '320': '',
      };
      const ebayProductSearch: IProductSearchRequest = {
        category: mapAliExpressToEbayCategory[this.q.category] || '',
        maxPrice: this.q.maxPrice,
        minPrice: this.q.minPrice,
        keywords: this.q.keywords,
        page: this.q.page,
        pageSize: this.q.pageSize,
      };
      this._ebaySearchService.productSearch(ebayProductSearch).subscribe(response => {
        response ? this.productsEbay = response.products : this.showMessage();
        this.spinnerEbay = false;

        this.processPricing();
      });
    }

  }

  public updateBookmarks() {
    const responseResult = this._bookmarkService.getBookmarks();
    responseResult ? responseResult.subscribe(response => this.bookmarks = response) : this.showMessage();
  }

  public toggleBookmark(product) {
    this.checkBookmark(product.item.id) ?
      this._bookmarkService.removeBookmark(this.bookmarks.find(
        bookmark => bookmark.sourceId === product.item.id).id
      ).subscribe(response => {
        !response ? this.showMessage() : this.updateBookmarks();
      }
      ) :
      this._bookmarkService.addBookmark(product.item.id, product.type, product.item).subscribe(response => {
        !response ? this.showMessage() : this.updateBookmarks();
      }
      );
  }

  public checkBookmark(product) {
    if (this.bookmarks.length !== 0) {
      return this.bookmarks.some(item => item.sourceId === product);
    } else {
      return false;
    }
  }

  public showMessage() {
    // this._alert.error('we\'re sorry the search request failed, please try again');
  }

  private processPricing() {

    if (!this.expandedProduct) {
      this.filteredProductsAmazon = this.productsAmazon;
      this.filteredProductsEbay = this.productsEbay;
      return;
    }

    // Amazon
    if (this.expandedSource === 'ebay') {
      this.filteredProductsAmazon = _(this.productsAmazon)
        .map((item: IProductView) => {
          return this.applyProfits(item);
        })
        .filter((item: IProductView) => {
          return item.profit > 1;
        })
        .sortBy('profit')
        .reverse()
        .value();
    } else {
      this.filteredProductsAmazon = this.productsAmazon;
    }

    // Ebay
    if (this.expandedSource === 'amazon') {
      this.filteredProductsEbay = _(this.productsEbay)
        .map((item: IProductView) => {
          return this.applyProfits(item);
        })
        .filter((item: IProductView) => {
          return item.profit > 1;
        })
        .sortBy('profit')
        .reverse()
        .value();
    } else {
      this.filteredProductsEbay = this.productsEbay;
    }
  }

  private applyProfits(item: IProductView) {
    item.profit = item.price - this.expandedProduct.price;
    item.marginPercent = item.profit / item.price * 100;
    return item;
  }

}
