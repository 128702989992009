import {Component, OnInit, Input, EventEmitter, Output} from '@angular/core';
import {IBookmarkView} from '../../../../../../../../server/src/interfaces/IBookmarkView';
import {BOOKMARK_TYPE} from '../../../../../../../../server/src/Constants';
import { IProductView } from '../../../../../../../../server/src/interfaces/IProductView';

@Component({
  selector: 'app-ebay-product',
  templateUrl: './ebay-product.component.html',
  styleUrls: ['./ebay-product.component.scss']
})
export class EbayProductComponent implements OnInit {
  @Input() products;
  @Input() expandedProduct: IProductView;
  @Input() expandedSource: string;
  @Output() bookmark = new EventEmitter<object>();
  @Output() productExpanded = new EventEmitter<IProductView>();
  @Input() bookmarks: IBookmarkView[];
  public bookmarkType: string = BOOKMARK_TYPE.EBAY_PRODUCT;

  public constructor() { }

  public ngOnInit() {}

  public toggleBookmark(product) {
    this.bookmark.emit({
      item: product,
      type: this.bookmarkType
    });
  }

  public checkBookmark(product) {
    if (this.bookmarks.length !== 0) {
      return this.bookmarks.some(item => item.sourceId === product);
    } else {
      return false;
    }
  }

  public onExpand(product: IProductView) {
    this.productExpanded.emit(product);
  }

}
