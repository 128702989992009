import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

  tabsName = ['spressio', 'shopify products', 'shopify stores', 'arbitrage price check', 'bookmarked'];
  activeTab = this.tabsName[0]; // "arbitrage dropship"; //
  activeButtonIndex = 0;

  constructor(private render: Renderer2, private el: ElementRef) {
  }

  public get containerStyle(): any {
    if (this.activeTab === 'spressio') {
      return { overflow: 'hidden' };
    }
  }

  public ngOnInit() {
    this.checkFooterDisplay();
  }

  public tabChange(index: number) {
    this.activeTab = this.tabsName[index];
    this.activeButtonIndex = index;
    this.checkFooterDisplay();
  }

  private checkFooterDisplay() {
    // Don't display footer on Arb tab
    try {
      const el: any = window.document.querySelector('.footer');
      if (this.activeTab === 'arbitrage price check' || this.activeTab === 'spressio') {
        el.style.display = 'none';
      } else {
        el.style.display = '';
      }
    } catch (error) {

    }
  }
}
