import { OnInit, Component } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators} from '@angular/forms';

import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-password-reset-request',
  templateUrl: './password-reset-request.component.html',
  styleUrls: ['./password-reset-request.component.css']
})
export class PasswordResetRequestComponent implements OnInit {

  public requestSent: boolean;
  public form: FormGroup;
  public isBusy: boolean;

  constructor(
    private _authService: AuthService,
  ) {
  }

  public ngOnInit() {
    // this._authService.clearToken();
    this.form = new FormGroup({
      email: new FormControl('', [
        Validators.required,
        Validators.maxLength(500),
        Validators.email,
      ]),
    });
  }

  public onSubmit() {

    if (!this.form.valid) {
      return;
    }

    this.isBusy = true;
    this.form.disable();

    this._authService.passwordResetRequest(this.form.value.email)
      .subscribe(result => {

        if (result) {

          // display message
          this.requestSent = true;

        } else {

        }

        this.isBusy = false;
        this.form.enable();

      });

  }

}
