import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { LoggerService } from './services/logger.service';
import { AppConfig } from './app.config';

@Injectable()
export class AppInitialiser {

    constructor(
        private _http: HttpClient,
        private _appConfig: AppConfig,
        private _loggerService: LoggerService,
    ) {
    }

    public async load(): Promise<any> {

        this._loggerService.info('AppInitialiser', 'load', 'started');
        try {
            const data = await this._http.get<any>('api/client/config').toPromise();
            this._appConfig.googleRecaptchaSiteKey = data.googleRecaptchaSiteKey;
            this._appConfig.envName = data.envName;
            this._appConfig.spressioIframeBaseUrl = data.spressioIframeBaseUrl;
        } catch (error) {

            this._loggerService.error('AppInitialiser', 'load', 'failed to load config', error);
        }
        this._loggerService.info('AppInitialiser', 'load', 'Completed');
        return Promise.resolve();
    }

}
