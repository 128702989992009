import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { IAmazonSearchRequest } from '../../../../../../../server/src/interfaces/IAmazonSearchRequest';
import { IEbaySearchRequest } from '../../../../../../../server/src/interfaces/IEbaySearchRequest';
import { AlertService } from '../../../../services/alert.service';

@Component({
  selector: 'app-amazon-ebay-filter',
  templateUrl: './amazon-ebay-filter.component.html',
  styleUrls: ['./amazon-ebay-filter.component.scss']
})
export class AmazonEbayFilterComponent implements OnInit {
  @Output() request = new EventEmitter<IAmazonSearchRequest | IEbaySearchRequest>();
  @Input() hotProductsFilterActive = false;
  @Input() amazonFilterActive = false;
  @Input() ebayFilterActive = false;
  q: IAmazonSearchRequest | IEbaySearchRequest;
  keyword: string;

  hotCategoryFilterName = 'Select a category';
  amazonCategoryFilterName = 'Select a category';
  ebayCategoryFilterName = 'Select a category';
  minPriceFilterName = 'Min Price';
  maxPriceFilterName = 'Max Price';
  priceVals = [0, 0, 5, 10, 30, 60, 100, 150, 300];

  filters = {
    hotCategory: {
      active: false,
      names: [
        'All',
        'Apparel & Accessories',
        'Automobiles & Motorcycles',
        'Beauty & Health',
        'Books for Local Russian',
        'Computer & Office',
        'Home Improvement',
        'Consumer Electronics',
        'Electrical Equipment & Supplies',
        'Electronic Components & Supplies',
        'Food',
        'Furniture',
        'Hair & Accessories',
        'Hardware',
        'Home & Garden',
        'Home Appliances',
        'Industry & Business',
        'Jewelry & Accessories',
        'Lights & Lighting',
        'Luggage & Bags',
        'Mother & Kids',
        'Office & School Supplies',
        'Phones & Telecommunications',
        'Security & Protection',
        'Shoes',
        'Special Category',
        'Sports & Entertainment',
        'Tools',
        'Toys & Hobbies',
        'Travel and Coupon Services',
        'Watches',
        'Weddings & Events',
      ],
      vals: [
        '',
        '3',
        '34',
        '66',
        '200004360',
        '7',
        '13',
        '44',
        '5',
        '502',
        '2',
        '1503',
        '200003655',
        '42',
        '15',
        '6',
        '200001996',
        '36',
        '39',
        '1524',
        '1501',
        '21',
        '509',
        '30',
        '322',
        '200001075',
        '18',
        '1420',
        '26',
        '200003498',
        '1511',
        '320',
      ]
    },
    amazonCategory: {
      active: false,
      names: ['All', 'Wine', 'Wireless', 'Arts And Crafts', 'Miscellaneous', 'Electronics', 'Jewelry',
        'Mobile Apps', 'Photo', 'Shoes', 'Kindle Store', 'Automotive', 'Pantry', 'Musical Instruments', 'Digital Music',
        'Gift Cards', 'Fashion Baby', 'Fashion Girls', 'Gourmet Food', 'Home Garden', 'Music Tracks', 'Unbox Video',
        'Fashion Women', 'Video Games', 'Fashion Men', 'Kitchen', 'Video', 'Software', 'Beauty', 'Grocery', 'Fashion Boys',
        'Industrial', 'Pet Supplies', 'Office Products', 'Magazines', 'Watches', 'Luggage', 'Outdoor Living', 'Toys',
        'Sporting Goods', 'PC Hardware', 'Movies', 'Books', 'Collectibles', 'Handmade', 'VHS', 'MP3 Downloads', 'Fashion',
        'Tools', 'Baby', 'Apparel', 'Marketplace', 'DVD', 'Appliances', 'Music', 'Lawn And Garden', 'Wireless Accessories',
        'Blended', 'Health PersonalCare', 'Classical']
    },
    ebayCategory: {
      active: false,
      names: ['All', 'Antiques', 'Art', 'Baby', 'Books', 'Business', 'Cameras, Photo',
        'Clothing, Shoes, Accessories', 'Coins, Currency', 'Collectibles', 'Computers, Tablets',
        'Consumer Electronics', 'Crafts', 'DVD, Movies', 'Entertainment Memorabilia', 'Health, Beauty', 'Home, Garden',
        'Jewellery, Watches', 'Mobile Phones, Accessories', 'Music',
        'Musical Instruments', 'Pottery, Glass', 'Real Estate', 'Sporting Goods', 'Sporting Memorabilia', 'Stamps',
        'Toys, Hobbies', 'Vehicles', 'Vehicle Parts, Accessories', 'Video Games', 'Deal Vouchers, Gift Cards', 'Everything Else'],
      vals: ['', '20081', '550', '2984', '267', '12576', '625', '11450', '11116', '1', '58058', '293', '14339',
        '11232', '45100', '26395', '11700', '281', '15032', '11233', '173484', '870', '10542', '382', '64482', '260', '220',
        '9800', '6028', '6028', '1249', '172008']
    },
    minPrice: {
      active: false,
      names: ['No Filter', 'Free', '5', '10', '30', '60', '100', '150', '300']
    },
    maxPrice: {
      active: false,
      names: ['No Filter', 'Free', '5', '10', '30', '60', '100', '150', '300']
    },
  };

  constructor(
    private _alert: AlertService
  ) { }

  ngOnInit() {
    this.keyword = '';
    this.q = {
      page: 1,
      pageSize: 12,
      keywords: ''
    };
  }

  applyFilters() {
    if (!this.hotProductsFilterActive && this.keyword.length === 0) {
      this._alert.error('keywords are required');
    } else {
      this.q.keywords = this.keyword;
      this.request.emit(this.q);
    }
  }

  setHotCategoryFilterName(filterName, index) {
    this.hotCategoryFilterName = filterName;
    if (filterName === this.filters.hotCategory.names[0]) {
      Object.keys(this.q).indexOf('category') !== -1 ? delete this.q.category : null;
    } else {
      this.q.category = this.filters.hotCategory.vals[index];
    }
  }

  setAmazonCategoryFilterName(filterName) {
    this.amazonCategoryFilterName = filterName;
    if (filterName === this.filters.amazonCategory.names[0]) {
      Object.keys(this.q).indexOf('category') !== -1 ? delete this.q.category : null;
    } else {
      this.q.category = filterName.replace(/\s/g, '');
    }
  }

  setEbayCategoryFilterName(filterName, index) {
    this.ebayCategoryFilterName = filterName;
    if (filterName === this.filters.ebayCategory.names[0]) {
      Object.keys(this.q).indexOf('category') !== -1 ? delete this.q.category : null;
    } else {
      this.q.category = this.filters.ebayCategory.vals[index];
    }
  }

  setMinPriceFilterName(filterName, index) {
    this.minPriceFilterName = filterName;
    if (filterName === this.filters.minPrice.names[0]) {
      this.q.minPrice !== undefined ? delete this.q.minPrice : null;
    } else {
      this.q.minPrice = this.priceVals[index];
    }
  }

  setMaxPriceFilterName(filterName, index) {
    this.maxPriceFilterName = filterName;
    if (filterName === this.filters.minPrice.names[0]) {
      this.q.maxPrice !== undefined ? delete this.q.maxPrice : null;
    } else {
      this.q.maxPrice = this.priceVals[index];
    }
  }

  showFilterOptions(filterName?: string, event?) {
    const keys = Object.keys(this.filters);
    keys.forEach((key) => {
      if (key === filterName) {
        this.filters[key].active = !this.filters[key].active;
        event.stopPropagation();
      } else {
        this.filters[key].active = false;
      }
    });
  }

  @HostListener('document:click')
  clickout() {
    const keys = Object.keys(this.filters);
    keys.forEach((key) => {
      this.filters[key].active = false;
    });
  }
}
