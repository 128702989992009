import { Injectable } from '@angular/core';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';
import {IBookmarkView} from '../../../../../../server/src/interfaces/IBookmarkView';
import {BackendService} from '../../../shared/services/backend.service';


@Injectable()
export class BookmarkedService {

  constructor(
    private backendService: BackendService
  ) {
  }

  getBookmarks(): Observable<IBookmarkView[]> {
    const url: string = (environment.urls.members as any).getBookmarks;
    return this.backendService
      .get(url)
      .pipe(resp => resp);
  }

}
