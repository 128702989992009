import {Component, Input, OnInit} from '@angular/core';
import {IStoreView} from '../../../../../../../../server/src/interfaces/IStoreView';
import {GroupByPipe, KeysPipe, FilterByPipe} from 'ngx-pipes';

@Component({
  selector: 'app-popup-installed-apps',
  templateUrl: './popup-installed-apps.component.html',
  styleUrls: ['./popup-installed-apps.component.scss']
})
export class PopupInstalledAppsComponent implements OnInit {
  @Input() store: IStoreView;

  constructor() { }

  ngOnInit() {}

}
