import {Component, Input, OnInit} from '@angular/core';
import {IStoreView} from '../../../../../../../../server/src/interfaces/IStoreView';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

@Component({
  selector: 'app-popup-traffic-analysis',
  templateUrl: './popup-traffic-analysis.component.html',
  styleUrls: ['./popup-traffic-analysis.component.scss'],
})

export class PopupTrafficAnalysisComponent implements OnInit {
  @Input() store: IStoreView;
  @Input() tabIndex: number;
  safeTrafficUrl: SafeResourceUrl;
  safeSourceUrl: SafeResourceUrl;

  constructor(
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    const trafficUrl = '/embed/' + this.store.id;
    this.safeTrafficUrl = this.sanitizer.bypassSecurityTrustResourceUrl(trafficUrl);
  }

}
