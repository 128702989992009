import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'accessLevel'
})
export class AccessLevelPipe implements PipeTransform {
    transform(value: string): string {

        switch (value) {
            case '0':
                return 'No access';
            case '40':
                return 'Platinum';
            case '42':
                return 'Platinum + 2';
            case '45':
                return 'Platinum + 5';
            case '50':
                return 'Platinum + 10';
            case '60':
                return 'Platinum + Hot Products';
            case '90':
                return 'Admin';
            default:
                return 'Unknown';
        }
    }
}
