import { Component } from '@angular/core';

@Component({
    selector: 'app-bonus',
    templateUrl: './bonus-products.component.html',
    styleUrls: ['./bonus-products.component.css'],
})

export class BonusProductsComponent {
    constructor() { }
}
