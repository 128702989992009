import {Component, Input, OnInit} from '@angular/core';
import {IStoreView} from '../../../../../../../../server/src/interfaces/IStoreView';

@Component({
  selector: 'app-popup-product-insights',
  templateUrl: './popup-product-insights.component.html',
  styleUrls: ['./popup-product-insights.component.scss']
})
export class PopupProductInsightsComponent implements OnInit {
  @Input() store: IStoreView;
  recentProdsChart: object;

  constructor() { }

  ngOnInit() {
    const lables = this.store.recentProductsChartLabels ? this.store.recentProductsChartLabels
      .split(',').map(item => item.replace('\'', '')) : null;
    const charData = this.store.recentProductsChartValues ? this.store.recentProductsChartValues.split(',') : null;
    this.recentProdsChart = {
      type: 'bar',
      data: {
        labels: lables,
        datasets: [
          {
            label: 'Products Launched',
            data: charData,
            backgroundColor: 'rgba(77, 173, 73, 0.5)',
            borderColor: '#4DAD49',
            borderWidth: 1
          }
        ]
      },
      options: {
        legend: {
          display: false
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true
              }
            }
          ],
          xAxes: [{
            gridLines: {
              lineWidth: 0,
              color: 'rgba(255,255,255,0)',
              zeroLineColor: 'rgba(255,255,255,0)'
            }
          }]
        }
      }
    };
  }

  countDays(lastUpdate) {
    const curDate = Date.now();
    const lastUpdateMS = Date.parse(lastUpdate);
    return Math.ceil(Math.abs(curDate - lastUpdateMS) / (1000 * 3600 * 24));
  }
}
