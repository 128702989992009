import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { ACCESS_TOKEN } from '../../constants';
import { Utilities } from '../../utilities';
import { IJsonWebToken } from '../../interfaces/IJsonWebToken';

@Injectable()
export class AuthService {
  private _baseUrl = '/api/security';

  constructor(private _apiService: HttpClient, private _router: Router) {
  }

  public get isPlatinumPro(): boolean {
    const token: IJsonWebToken = Utilities.decodeJwt(localStorage.getItem(ACCESS_TOKEN));
    return token && token.lev === '60';
  }

  public get isAdmin(): boolean {
    const token: IJsonWebToken = Utilities.decodeJwt(localStorage.getItem(ACCESS_TOKEN));
    return token && token.lev === '90';
  }

  public get authorizationToken(): string {
    return localStorage.getItem(ACCESS_TOKEN);
  }

  public setAuthorizationToken(authToken: string) {
    localStorage.setItem(ACCESS_TOKEN, authToken);
  }

  public verifyToken(): Observable<any> {
    return this._apiService.get(`${this._baseUrl}/token/verify`);
  }

  public authenticate(email: string, password: string, recaptchaResponse: string): Observable<any> {
    return this._apiService.post(`${this._baseUrl}/authenticate`, { email, password, recaptchaResponse });
  }

  public passwordResetRequest(email: string): Observable<any> {
    return this._apiService.post(`${this._baseUrl}/password/reset/request`, { email });
  }

  public passwordResetExecute(email: string, password: string, token: string): Observable<any> {
    return this._apiService.post(`${this._baseUrl}/password/reset/execute`, { email, password, token });
  }

  public registerExecute(email: string, password: string, token: string): Observable<any> {
    return this._apiService.post(`${this._baseUrl}/registration/execute`, { email, password, token });
  }

  public registerVerify(token: string): Observable<any> {
    return this._apiService.post(`${this._baseUrl}/registration/verify`, { token });
  }

  public logout() {
    this.clearToken();
    this._router.navigate(['/login']);
  }

  public clearToken() {
    localStorage.clear();
  }

}
