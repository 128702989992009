import { Component, OnInit, HostListener } from '@angular/core';
import { fadeInOnEnterAnimation, fadeOutOnLeaveAnimation } from 'angular-animations';

@Component({
  selector: 'app-goto-button',
  templateUrl: './goto-button.component.html',
  styleUrls: ['./goto-button.component.scss'],
  animations: [
    fadeInOnEnterAnimation(),
    fadeOutOnLeaveAnimation(),
  ],
})
export class GotoButtonComponent implements OnInit {

  public get showButton(): boolean {
    // console.log(this._scrollPosition);
    return this._scrollPosition > 100;
  }

  private _scrollPosition: number;

  @HostListener('window:scroll', ['$event'])
  public onWindowScroll() {
    this._scrollPosition = window.scrollY;
  }

  constructor() { }

  public buttonClick() {
    window.scrollTo(0, 0);
  }

  ngOnInit() {
  }

}
