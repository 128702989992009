import { Component, OnInit } from '@angular/core';
import {IProductView} from '../../../../../../server/src/interfaces/IProductView';
import {IAmazonSearchRequest} from '../../../../../../server/src/interfaces/IAmazonSearchRequest';
import {AmazonProductSearchService} from './amazon-product-search.service';
import {AlertService} from '../../../services/alert.service';
import {IBookmarkView} from '../../../../../../server/src/interfaces/IBookmarkView';
import {BookmarkSharedService} from '../../../shared/services/bookmark-shared.service';

@Component({
  selector: 'app-amazon-product-search',
  templateUrl: './amazon-product-search.component.html',
  styleUrls: ['./amazon-product-search.component.scss']
})
export class AmazonProductSearchComponent implements OnInit {
  bookmarks: IBookmarkView[];
  products: IProductView[] = [];
  pageSize = 12;
  spinner = false;

  q: IAmazonSearchRequest = {
    page: 1,
    pageSize: Infinity,
    keywords: ''
  };

  constructor(
    private _amazonProductService: AmazonProductSearchService,
    private _bookmarkService: BookmarkSharedService,
    private _alert: AlertService
  ) { }

  ngOnInit() {
    this.updateBookmarks();
  }

  onFilterUpdate(q: IAmazonSearchRequest) {
    this.q = q;
    this.updateCollection(1);
  }

  updateCollection(curPage: number) {
    this.spinner = true;
    this._amazonProductService.productSearch(this.q).subscribe(response => {
      response ? this.products = response.products : this.showMessage();
      this.spinner = false;
    });
  }

  updateBookmarks() {
    const responseResult = this._bookmarkService.getBookmarks();
    responseResult ? responseResult.subscribe(response => this.bookmarks = response) : this.showMessage();
  }

  toggleBookmark(product) {
    this.checkBookmark(product.item.id) ?
      this._bookmarkService.removeBookmark(this.bookmarks.find(
        bookmark => bookmark.sourceId === product.item.id).id
      ).subscribe(response => {
          !response ? this.showMessage() : this.updateBookmarks();
        }
      ) :
      this._bookmarkService.addBookmark(product.item.id, product.type, product.item).subscribe(response => {
          !response ? this.showMessage() : this.updateBookmarks();
        }
      );
  }

  checkBookmark(product) {
    if (this.bookmarks.length !== 0) {
      return this.bookmarks.some(item => item.sourceId === product);
    } else {
      return false;
    }
  }

  showMessage() {
    this.spinner = false;
    // this._alert.error('we\'re sorry the search request failed, please try again');
  }
}
