import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { IPaginatedResponse } from '../../../../server/src/interfaces/IPaginatedResponse';
import { IUserView } from '../../../../server/src/interfaces/IUserView';

@Injectable()
export class AdminService {

  private _baseUrl = '/api/admin';

  constructor(private _apiService: HttpClient) {
  }

  // Domain Inspector
  public getDomainStore(domain: string): Observable<any> {
    return this._apiService.get(`${this._baseUrl}/domain-store/${domain}`);
  }

  public processDomain(domain: string): Observable<any> {
    return this._apiService.post(`${this._baseUrl}/process-domain/`, { domain });
  }

  public deactivateStore(domain: string): Observable<any> {
    return this._apiService.post(`${this._baseUrl}/deactivate-store/`, { domain });
  }

  //
  // User Admin
  //
  public getUsers(pageNumber: number, pageSize: number, search: string): Observable<IPaginatedResponse<IUserView>> {
    return this._apiService.get<IPaginatedResponse<IUserView>>(`${this._baseUrl}/users`, {
      params: {
        pageNumber: pageNumber.toString(),
        pageSize: pageSize.toString(),
        search: search || '',
      }
    });
  }

  public getUser(id: string): Observable<IUserView> {
    return this._apiService.get<IUserView>(`${this._baseUrl}/user/${id}`);
  }

  public addUser(user: IUserView): Observable<any> {
    return this._apiService.post(`${this._baseUrl}/user/`, user);
  }

  public updateUser(user: IUserView): Observable<any> {
    return this._apiService.put(`${this._baseUrl}/user/${user.id}`, user);
  }

  public resendRegistrationEmail(id: string): Observable<any> {
    return this._apiService.post(`${this._baseUrl}/user/resendregistrationemail`, { id });
  }

}
