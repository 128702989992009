import { Injectable } from '@angular/core';
import {
  HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse
} from '@angular/common/http';

import { AuthService } from '../services/auth.service';
import { tap } from 'rxjs/operators';
import { LoggerService } from '../services/logger.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService, private logger: LoggerService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    // Get the auth token from the service.
    const self = this;
    const authToken = this.authService.authorizationToken;

    // Clone the request and set the new header
    const authReq = req.clone({ setHeaders: { 'X-Access-Token': authToken || '' } });

    // send cloned request with header to the next handler.
    return next.handle(authReq).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
          const authTokenHeader = event.headers.get('X-Access-Token');
          if (authTokenHeader) {
            // Set auth token in Auth Service
            self.authService.setAuthorizationToken(authTokenHeader);
          }
        }
      },
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 403) {
              this.authService.logout();
            } else {
              this.logger.info('AuthInterceptor', 'intercept', 'unexpected error response', {
                statusCode: err.status,
                error: err.message,
              });
            }
          }
        }
      )
    );
  }
}
