export const PASSWORD_REGEX: RegExp = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{10,100}/;
export const ACCESS_TOKEN = 'inttoken';


export const AMAZON_NODES: any[] = [

    {
        'node': 'https://www.amazon.com/Best-Sellers/zgbs/amazon-devices',
        'title': 'Amazon Devices & Accessories'
    },
    {
        'node': 'https://www.amazon.com/Best-Sellers-Amazon-Launchpad/zgbs/boost',
        'title': 'Amazon Launchpad'
    },
    {
        'node': 'https://www.amazon.com/Best-Sellers-Appliances/zgbs/appliances',
        'title': 'Appliances'
    },
    {
        'node': 'https://www.amazon.com/Best-Sellers-Appstore-Android/zgbs/mobile-apps',
        'title': 'Apps & Games'
    },
    {
        'node': 'https://www.amazon.com/Best-Sellers-Arts-Crafts-Sewing/zgbs/arts-crafts',
        'title': 'Arts, Crafts & Sewing'
    },
    {
        'node': 'https://www.amazon.com/Best-Sellers-Automotive/zgbs/automotive',
        'title': 'Automotive'
    },
    {
        'node': 'https://www.amazon.com/Best-Sellers-Baby/zgbs/baby-products',
        'title': 'Baby'
    },
    {
        'node': 'https://www.amazon.com/Best-Sellers-Beauty/zgbs/beauty',
        'title': 'Beauty & Personal Care'
    },
    {
        'node': 'https://www.amazon.com/best-sellers-books-Amazon/zgbs/books',
        'title': 'Books'
    },
    {
        'node': 'https://www.amazon.com/best-sellers-music-albums/zgbs/music',
        'title': 'CDs & Vinyl'
    },
    {
        'node': 'https://www.amazon.com/best-sellers-camera-photo/zgbs/photo',
        'title': 'Camera & Photo'
    },
    {
        'node': 'https://www.amazon.com/Best-Sellers-Cell-Phones-Accessories/zgbs/wireless',
        'title': 'Cell Phones & Accessories'
    },
    {
        'node': 'https://www.amazon.com/Best-Sellers/zgbs/fashion',
        'title': 'Clothing, Shoes & Jewelry'
    },
    {
        'node': 'https://www.amazon.com/Best-Sellers-Collectible-Coins/zgbs/coins',
        'title': 'Collectible Coins'
    },
    {
        'node': 'https://www.amazon.com/Best-Sellers-Computers-Accessories/zgbs/pc',
        'title': 'Computers & Accessories'
    },
    {
        'node': 'https://www.amazon.com/Best-Sellers-MP3-Downloads/zgbs/dmusic',
        'title': 'Digital Music'
    },
    {
        'node': 'https://www.amazon.com/Best-Sellers-Electronics/zgbs/electronics',
        'title': 'Electronics'
    },
    {
        'node': 'https://www.amazon.com/Best-Sellers-Entertainment-Collectibles/zgbs/entertainment-collectibles',
        'title': 'Entertainment Collectibles'
    },
    {
        'node': 'https://www.amazon.com/Best-Sellers-Gift-Cards/zgbs/gift-cards',
        'title': 'Gift Cards'
    },
    {
        'node': 'https://www.amazon.com/Best-Sellers-Grocery-Gourmet-Food/zgbs/grocery',
        'title': 'Grocery & Gourmet Food'
    },
    {
        'node': 'https://www.amazon.com/Best-Sellers-Handmade/zgbs/handmade',
        'title': 'Handmade Products'
    },
    {
        'node': 'https://www.amazon.com/Best-Sellers-Health-Personal-Care/zgbs/hpc',
        'title': 'Health & Household'
    },
    {
        'node': 'https://www.amazon.com/Best-Sellers-Home-Kitchen/zgbs/home-garden',
        'title': 'Home & Kitchen'
    },
    {
        'node': 'https://www.amazon.com/Best-Sellers-Industrial-Scientific/zgbs/industrial',
        'title': 'Industrial & Scientific'
    },
    {
        'node': 'https://www.amazon.com/Best-Sellers-Kindle-Store/zgbs/digital-text',
        'title': 'Kindle Store'
    },
    {
        'node': 'https://www.amazon.com/Best-Sellers-Kitchen-Dining/zgbs/kitchen',
        'title': 'Kitchen & Dining'
    },
    {
        'node': 'https://www.amazon.com/Best-Sellers-Magazines/zgbs/magazines',
        'title': 'Magazine Subscriptions'
    },
    {
        'node': 'https://www.amazon.com/best-sellers-movies-TV-DVD-Blu-ray/zgbs/movies-tv',
        'title': 'Movies & TV'
    },
    {
        'node': 'https://www.amazon.com/Best-Sellers-Musical-Instruments/zgbs/musical-instruments',
        'title': 'Musical Instruments'
    },
    {
        'node': 'https://www.amazon.com/Best-Sellers-Office-Products/zgbs/office-products',
        'title': 'Office Products'
    },
    {
        'node': 'https://www.amazon.com/Best-Sellers-Garden-Outdoor/zgbs/lawn-garden',
        'title': 'Patio, Lawn & Garden'
    },
    {
        'node': 'https://www.amazon.com/Best-Sellers-Pet-Supplies/zgbs/pet-supplies',
        'title': 'Pet Supplies'
    },
    {
        'node': 'https://www.amazon.com/Best-Sellers-Prime-Pantry/zgbs/pantry',
        'title': 'Prime Pantry'
    },
    {
        'node': 'https://www.amazon.com/Best-Sellers/zgbs/smart-home',
        'title': 'Smart Home'
    },
    {
        'node': 'https://www.amazon.com/best-sellers-software/zgbs/software',
        'title': 'Software'
    },
    {
        'node': 'https://www.amazon.com/Best-Sellers-Sports-Outdoors/zgbs/sporting-goods',
        'title': 'Sports & Outdoors'
    },
    {
        'node': 'https://www.amazon.com/Best-Sellers-Sports-Collectibles/zgbs/sports-collectibles',
        'title': 'Sports Collectibles'
    },
    {
        'node': 'https://www.amazon.com/Best-Sellers-Home-Improvement/zgbs/hi',
        'title': 'Tools & Home Improvement'
    },
    {
        'node': 'https://www.amazon.com/Best-Sellers-Toys-Games/zgbs/toys-and-games',
        'title': 'Toys & Games'
    },
    {
        'node': 'https://www.amazon.com/best-sellers-video-games/zgbs/videogames',
        'title': 'Video Games'
    }

];
