import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ecom-academy',
  templateUrl: './ecom-academy.component.html',
  styleUrls: ['./ecom-academy.component.css']
})
export class EcomAcademyComponent implements OnInit {

  public videoModules: any[] = [
    {
      title: 'Module 1',
      videos: [
        {
          title: '1. Creating your account',
          id: 'rWL4ijiZvnY',
        },
        {
          title: '2. Dashboard walkthrough',
          id: 'iaQpiAEgmB4',
        },
        {
          title: '3. Setting up your pages',
          id: 'zMq21YuG8qg',
        },
        {
          title: '4. Choosing the perfect theme',
          id: 'W2SeH0LWt4c',
        },
        {
          title: '5. Adding brand identity',
          id: 'lCwqJBNboYQ',
        },
        {
          title: '6. Must have apps',
          id: '45RBBbIQ4Xg',
        },
      ],
    },
    {
      title: 'Module 2',
      videos: [
        {
          title: '1. Selecting store niche',
          id: '2JnuUTs1P9s',
        },
        {
          title: '2. Researching top products',
          id: 'etIoEJvRjSc',
        },
        {
          title: '3. Installing Oberlo',
          id: 'VXUG57dpOTU',
        },
        {
          title: '4. Ali express vs Oberlo',
          id: 'xHCs_d6U5N4',
        },
        {
          title: '5. What are collections',
          id: 'Np5cjiJFYMI',
        },
        {
          title: '6. Adding products',
          id: 'Nmhm5_ZHzi4',
        },
        {
          title: '7. Auto update products',
          id: 'izAZkU80q-o',
        },
        {
          title: '8. Adding reviews',
          id: 'NuADqe3oWTk',
        },
      ],
    },
    {
      title: 'Module 3',
      videos: [
        {
          title: '1. Choosing a Shopify plan',
          id: 'je6HLLOmgv4',
        },
        {
          title: '2. Linking your domain',
          id: '1TKdFNpwG7M',
        },
        {
          title: '3. Title and description',
          id: 'fh-TmuMaKV0',
        },
        {
          title: '4. Shipping rates',
          id: 'Ok_1AYSTjMQ',
        },
        {
          title: '5. Payment methods',
          id: 'qOPca8Wy_d4',
        },
        {
          title: '6. Setting up Klaviyo 1',
          id: 'ybPXG13Uw90',
        },
        {
          title: '7. Setting up Klaviyo 2',
          id: 'LkSvq31w1HI',
        },
      ],
    },
    {
      title: 'Module 4',
      videos: [
        {
          title: '1. Create business account',
          id: 'L7aWLSjCuK4',
        },
        {
          title: '2. Installing your Facebook pixel',
          id: 'mXPG9uEESx8',
        },
        {
          title: '3. Uploading your Facebook catalog',
          id: '52nLmBryJO8',
        },
        {
          title: '4. Creating your Facebook ad',
          id: 'pFR1R_QrGJM',
        },
        {
          title: '5. Creating your retargeting ad',
          id: 'hm-4PtD5-hw',
        },
        {
          title: '6. How to scale your ads',
          id: 'pDQgP4FRtpc',
        },
      ],
    },
    {
      title: 'Module 5',
      videos: [
        {
          title: '1. Fulfilling orders with Oberlo',
          id: 'TlCYkzDiAhY',
        },
        {
          title: '2. Adding FAQ page',
          id: 'JBuHn2XuXOM',
        },
        {
          title: '3. Live Facebook chat app',
          id: 'PUwGY5a-qB4',
        },
        {
          title: '4. Your refund policy',
          id: '1InzzOcGcMg',
        },
        {
          title: '5. Live buyers app',
          id: '41bPgzcW_Aw',
        },
        {
          title: '6. Conclusion',
          id: 'KRPfHWDzViA',
        },
      ],
    },
  ];

  constructor() { }

  ngOnInit() {
  }

}
