import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ShopifyStoreSearchComponent } from './shopify-store-search.component';
import { ShopifyStoreSearchService } from './shopify-store-search.service';
import { ShopifyStoreModule } from './compnents/shopify-store/shopify-store.module';
import { SharedModule } from '../../../shared/shared.module';
import {PaginationModule} from '../../../shared/components/pagination/pagination.module';

@NgModule({

  imports: [
    CommonModule,
    RouterModule,
    ShopifyStoreModule,
    NgbModule,
    SharedModule,
    PaginationModule
  ],

  declarations: [ShopifyStoreSearchComponent],

  providers: [ShopifyStoreSearchService],

  exports: [ShopifyStoreSearchComponent]
})

export class ShopifyStoreSearchModule {
}
