import { faGear, faSignInAlt, faTimes, faCheck, faTrash, faEdit, faAt } from '@fortawesome/free-solid-svg-icons';
import { OnInit, Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';

import { AuthService } from '../../services/auth.service';
import { FormValidators } from '../../form-validators';

@Component({
  selector: 'app-register-execute',
  templateUrl: './register-execute.component.html',
  styleUrls: ['./register-execute.component.css']
})
export class RegisterExecuteComponent implements OnInit {

  faGear = faGear;
  faSignInAlt = faSignInAlt;
  faTimes = faTimes;
  faCheck = faCheck;
  faTrash = faTrash;
  faEdit = faEdit

  public errorMessage: string;
  public registrationComplete: boolean;
  public validToken = true;
  public validatingToken = true;
  public form: FormGroup;
  public isBusy: boolean;

  private _token: string;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _authService: AuthService,
  ) {
  }

  public ngOnInit() {

    // Clear any existing session token as we'll send user to login after this
    // this._authService.clearToken();

    this._route.params.subscribe(params => {
      this._token = params['token'];
    });

    this._authService.registerVerify(
      this._token,
    )
      .subscribe(result => {
        this.validToken = result.verified;
        this.validatingToken = false;
      });

    this.createForm();
  }

  public onSubmit(submittedForm: any): boolean {

    if (!this.form.valid) {
      return;
    }

    this.isBusy = true;
    this.form.disable();

    this._authService.registerExecute(
      this.form.value.email,
      this.form.value.password,
      this._token,
    )
      .subscribe(result => {
        if (result) {
          this._router.navigate(['/login']);
          this.registrationComplete = true;
        } else {
          this.errorMessage = 'Error occurred, check your email address and try again';
        }

        this.isBusy = false;
        this.form.enable();

      });

  }

  private createForm() {
    this.form = new FormGroup({
      email: new FormControl('', [
        Validators.required,
        Validators.email,
        Validators.maxLength(500),
      ]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(100),
       // Validators.pattern(PASSWORD_REGEX),
      ]),
      confirmPassword: new FormControl('', [
        Validators.required,
        Validators.maxLength(100),
      ]),
    }, {
        validators: [FormValidators.MatchPassword],
      });
  }

}
