import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { IProductSearchRequest } from '../../../../../../server/src/interfaces/IProductSearchRequest';
import { AlertService } from 'src/app/services/alert.service';

@Component({
  selector: 'app-dropship-filter',
  templateUrl: './dropship-filter.component.html',
  styleUrls: ['./dropship-filter.component.scss']
})
export class DropshipFilterComponent implements OnInit {
  @Output() request = new EventEmitter<IProductSearchRequest>();
  @Output() backSearchRequest = new EventEmitter<any>();

  public q: IProductSearchRequest;

  @Input() public keyword: string;
  @Input() public showBackButton: boolean;

  public sortByName = 'Best match';
  public categoryFilterName = 'Select a product category';
  public minPriceFilterName = 'Min Price';
  public maxPriceFilterName = 'Max Price';
  public sortByActive = false;

  filters = {
    category: {
      active: false,
      names: [
        'All',
        'Apparel & Accessories',
        'Automobiles & Motorcycles',
        'Beauty & Health',
        'Books for Local Russian',
        'Computer & Office',
        'Home Improvement',
        'Consumer Electronics',
        'Electrical Equipment & Supplies',
        'Electronic Components & Supplies',
        'Food',
        'Furniture',
        'Hair & Accessories',
        'Hardware',
        'Home & Garden',
        'Home Appliances',
        'Industry & Business',
        'Jewelry & Accessories',
        'Lights & Lighting',
        'Luggage & Bags',
        'Mother & Kids',
        'Office & School Supplies',
        'Phones & Telecommunications',
        'Security & Protection',
        'Shoes',
        'Special Category',
        'Sports & Entertainment',
        'Tools',
        'Toys & Hobbies',
        'Travel and Coupon Services',
        'Watches',
        'Weddings & Events',
      ],
      vals: [
        '',
        '3',
        '34',
        '66',
        '200004360',
        '7',
        '13',
        '44',
        '5',
        '502',
        '2',
        '1503',
        '200003655',
        '42',
        '15',
        '6',
        '200001996',
        '36',
        '39',
        '1524',
        '1501',
        '21',
        '509',
        '30',
        '322',
        '200001075',
        '18',
        '1420',
        '26',
        '200003498',
        '1511',
        '320',
      ]
    },
    minPrice: {
      active: false,
      names: ['No Filter', '5', '10', '30', '60', '100', '150', '300']
    },
    maxPrice: {
      active: false,
      names: ['No Filter', '5', '10', '30', '60', '100', '150', '300']
    },
  };

  constructor(
    private _alert: AlertService
  ) { }

  ngOnInit() {
    this.q = {
      page: 1,
      pageSize: 12,
      keywords: ''
    };
  }

  applyFilters() {
    if (this.keyword.length === 0) {
      this._alert.error('keywords are required');
    } else {
      this.q.keywords = this.keyword;
      this.request.emit(this.q);
    }
  }

  backSearch() {
    this.backSearchRequest.emit(this.q);
  }

  setSortBy(sortByName: string, sortByValue: string) {
    this.sortByName = sortByName;
    this.q.sortBy = sortByValue;
  }

  setCategoryFilterName(filterName, index) {
    this.categoryFilterName = filterName;
    if (filterName === this.filters.category.names[0]) {
      Object.keys(this.q).indexOf('category') !== -1 ? delete this.q.category : null;
    } else {
      this.q.category = this.filters.category.vals[index];
    }
  }

  setMinPriceFilterName(filterName, index) {
    this.minPriceFilterName = filterName;
    if (filterName === this.filters.minPrice.names[0]) {
      this.q.minPrice !== undefined ? delete this.q.minPrice : null;
    } else {
      this.q.minPrice = Number(this.filters.minPrice.names[index]);
    }
  }

  setMaxPriceFilterName(filterName, index) {
    this.maxPriceFilterName = filterName;
    if (filterName === this.filters.minPrice.names[0]) {
      this.q.maxPrice !== undefined ? delete this.q.maxPrice : null;
    } else {
      this.q.maxPrice = Number(this.filters.minPrice.names[index]);
    }
  }

  showSortByOptions(event?) {
    if (this.sortByActive) {
      this.sortByActive = false;
    } else {
      this.sortByActive = true;
      event.stopPropagation();
    }
  }

  showFilterOptions(filterName?: string, event?) {
    const keys = Object.keys(this.filters);
    keys.forEach((key) => {
      if (key === filterName) {
        this.filters[key].active = !this.filters[key].active;
        event.stopPropagation();
      } else {
        this.filters[key].active = false;
      }
    });
  }

  @HostListener('document:click')
  clickout() {
    const keys = Object.keys(this.filters);
    keys.forEach((key) => {
      this.filters[key].active = false;
    });
    this.sortByActive = false;
  }
}
