import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';

import { BackendService } from '../../../shared/services/backend.service';
import { environment } from '../../../../environments/environment';
import { IProductSearchRequest } from '../../../../../../server/src/interfaces/IProductSearchRequest';

@Injectable()
export class ShopifyProductSearchService {

  constructor(private backendService: BackendService) { }

  productSearch(request: IProductSearchRequest): any {
    const q: string = JSON.stringify(request);
    const url: string = environment.urls.members.productSearch;
    return this.backendService
      .get(url, {params: new HttpParams().set('q', q)})
      .pipe(response => response);
  }

}
