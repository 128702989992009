import { OnInit, Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-logout',
  template: '',
})

export class LogoutComponent implements OnInit {

  constructor(
    private _router: Router,
    private _authService: AuthService,
  ) {
  }

  public ngOnInit() {
    this._authService.logout();
    this._router.navigate(['/login']);

  }

}

