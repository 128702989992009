import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IBookmarkView } from '../../../../../../../../server/src/interfaces/IBookmarkView';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PopupComponent } from '../../../../../shared/components/popup/popup.component';
import { IStoreView } from '../../../../../../../../server/src/interfaces/IStoreView';
import { BOOKMARK_TYPE } from '../../../../../../../../server/src/Constants';
import { Utilities } from 'src/utilities';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-shopify-store',
  templateUrl: './shopify-store.component.html',
  styleUrls: ['./shopify-store.component.scss']
})
export class ShopifyStoreComponent implements OnInit {
  @Output() bookmark = new EventEmitter<object>();
  @Input() store: IStoreView;
  @Input() bookmarks: IBookmarkView[];
  instanceType = BOOKMARK_TYPE.SHOPIFY_STORE;
  prodIndex = 0;

  public getAliExpressSearchUrl(q: string) {
    return Utilities.getAliExpressSearchUrl(q);
  }

  constructor(
    public authService: AuthService,
    private _modalService: NgbModal,
    private _router: Router,
  ) { }

  ngOnInit() { }

  onProdChange(index: number) {
    this.prodIndex = index;
  }

  onActivateModal(store, tabIndex: number = 0, subTabIndex: number = 0) {
    const modalRef = this._modalService.open(PopupComponent);
    modalRef.componentInstance.store = store;
    modalRef.componentInstance.tabIndex = tabIndex;
    modalRef.componentInstance.subTabIndex = subTabIndex;
  }

  checkBookmark(product) {
    if (this.bookmarks.length !== 0) {
      return this.bookmarks.some(item => item.sourceId === product);
    } else {
      return false;
    }
  }

  toggleBookmark(store) {
    this.bookmark.emit({
      item: store,
      type: this.instanceType
    });
  }
}
