import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { IProductSearchResponse } from '../../../../../../server/src/interfaces/IProductSearchResponse';
import { BackendService } from '../../../shared/services/backend.service';
import { HttpParams } from '@angular/common/http';
import { IAliExpressProductSearchRequest } from '../../../../../../server/src/interfaces/IAliExpressProductSearchRequest';


@Injectable()
export class AliExpressProductSearchService {

  constructor(
    private _backEndService: BackendService
  ) { }

  public productSearch(request: IAliExpressProductSearchRequest): Observable<IProductSearchResponse> {
    const q: string = JSON.stringify(request);
    const url: string = (environment.urls.members as any).aliExpressProductsSearch;
    return this._backEndService
      .get(url, { params: new HttpParams().set('q', q) });
  }
}
