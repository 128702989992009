import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {RouterModule} from '@angular/router';
import { StoreTrafficComponent } from './store-traffic.component';
import { ChartComponent } from './chart.component';

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    RouterModule,
  ],
  declarations: [StoreTrafficComponent, ChartComponent],
  exports: [StoreTrafficComponent]
})
export class StoreAnalyticsModule { }
