import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EbayProductComponent } from './ebay-product.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [EbayProductComponent],
  exports: [EbayProductComponent]
})
export class EbayProductModule { }
