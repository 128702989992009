import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';

import { ShopifyStoreService } from './shopify-store.service';
import { ShopifyStoreComponent } from './shopify-store.component';
import { SharedModule } from '../../../../../shared/shared.module';

@NgModule({

  imports: [
    CommonModule,
    RouterModule,
    NgbModule,
    SharedModule
  ],

  declarations: [ShopifyStoreComponent],

  providers: [ShopifyStoreService],

  exports: [ShopifyStoreComponent]

})
export class ShopifyStoreModule {
}
