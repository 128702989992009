export type LoggerLevel = 'DEBUG' | 'INFO';

export enum CACHE_TYPE {
    LAMBDA = "lambda",
}

export enum SAVED_SEARCH_TYPE {
    EXPRESSO = "expresso",
}

export enum BOOKMARK_TYPE {
    SHOPIFY_STORE = "SHOPIFY_STORE",
    SHOPIFY_PRODUCT = "SHOPIFY_PRODUCT",
    AMAZON_PRODUCT = "AMAZON_PRODUCT",
    EBAY_PRODUCT = "EBAY_PRODUCT",
    ETSY_PRODUCT = "ETSY_PRODUCT",
    ALIEXPRESS_PRODUCT = "ALIEXPRESS_PRODUCT",
    SPRESSIO_PRODUCT = "SPRESSIO_PRODUCT",
}

export enum STORE_STATUS {
    PROCESSING = "PROCESSING",
    ACTIVE = "ACTIVE",
    INACTIVE = "INACTIVE",
}

export enum STORE_ERROR_CODE {
    DOMAIN_ANAYLTICS_FAILED = "DOMAIN_ANAYLTICS_FAILED",
    SCREENSHOT_FAILED = "SCREENSHOT_FAILED",
    RATE_LIMIT = "RATE_LIMIT",
    NOT_SHOPIFY = "NOT_SHOPIFY",
    NO_VENDOR_CHANGES = "NO_VENDOR_CHANGES",
    NO_BESTSELLERS = "NO_BESTSELLERS",
    HOMEPAGE_FAILED = "HOMEPAGE_FAILED",
    LAMBDA_TIMEOUT = "LAMBDA_TIMEOUT",
}

export const APPLICATION_JSON: string = "application/json";
export const CONTENT_TYPE_HEADER: string = "Content-Type";
export const ENV: any = "env";
export const EXPOSE_ERRORS: string = "exposeSwaggerValidationErrors";
export const LOCATION_HEADER: string = "Location";
export const OPERATION_ID: string = "operationId";
export const STACK_PROPERTY: string = "stack";
export const TEXT_HTML: string = "text/html";
export const X_ERROR_CODE: string = "X-Error-Code";
export const X_ERROR_MESSAGE: string = "X-Error-Message";
export const X_ERROR_REFERENCE: string = "X-Error-Reference";
export const X_SWAGGER_ROUTER_CONTROLLER: string = "x-swagger-router-controller";
export const REFERER: string = "referer";
export const USER_AGENT: string = "user-agent";
export const X_ACCESS_TOKEN: string = "x-access-token";
export const X_FORWARDED_FOR: string = "x-forwarded-for";
export const MAX_ALIEXPRESS_SCRAPING_TABS: number = 5; // Probably about the limit for the lambda browser

export const CHROME_EXTENSION_URL: string =
    "https://chrome.google.com/webstore/detail/intelligynce/moigkpepgfdfpachffkjjonjenohfmlh/reviews?hl=en-US";

export const AFFILIATES_SIGNUP_URL: string = "https://intelligynce.kartra.com/affiliates/signup";

export const CONTACT_US_URL: string = "https://customer-service-help.zendesk.com/hc/en-us/requests/new";

export enum ACCESS_LEVEL {
    PLATINUM = 40,
    PLATINUM_2 = 42,
    PLATINUM_5 = 45,
    PLATINUM_10 = 50,
    PLATINUM_PRO = 60,
    ADMIN = 90,
}

export const COUNTRIES: any = {
    AD: "Andorra",
    AE: "United Arab Emirates",
    AL: "Albania",
    AM: "Armenia",
    AN: "Netherlands Antilles",
    AR: "Argentina",
    AT: "Austria",
    AU: "Australia",
    AZ: "Azerbaijan",
    BA: "Bosniaand Herzegovina",
    BB: "Barbados",
    BD: "Bangladesh",
    BE: "Belgium",
    BG: "Bulgaria",
    BH: "Bahrain",
    BN: "Brunei",
    BO: "Bolivia",
    BR: "Brazil",
    BS: "Bahamas",
    BY: "Belarus",
    CA: "Canada",
    CEU: "Central Europe",
    CH: "Switzerland",
    CI: "Coted&#39;Ivoire",
    CL: "Chile",
    CM: "Cameroon",
    CN: "China",
    CO: "Colombia",
    CR: "CostaRica",
    CS: "Serbiaand Montenegro",
    CY: "Cyprus",
    CZ: "Czech Republic",
    DE: "Germany",
    DK: "Denmark",
    DO: "Dominican Republic",
    DZ: "Algeria",
    EC: "Ecuador",
    EE: "Estonia",
    EG: "Egypt",
    ES: "Spain",
    EU: "Europe",
    FI: "Finland",
    FR: "France",
    GB: "United Kingdom",
    GE: "Georgia",
    GF: "French Guiana",
    GH: "Ghana",
    GP: "Guadeloupe",
    GR: "Greece",
    GT: "Guatemala",
    GU: "Guam",
    HK: "HongKong",
    HN: "Honduras",
    HR: "Croatia",
    HU: "Hungary",
    ID: "Indonesia",
    IE: "Ireland",
    IL: "Israel",
    IN: "India",
    IQ: "Iraq",
    IR: "Iran",
    IS: "Iceland",
    IT: "Italy",
    JM: "Jamaica",
    JO: "Jordan",
    JP: "Japan",
    KE: "Kenya",
    KG: "Kyrgyzstan",
    KH: "Cambodia",
    KR: "South Korea",
    KW: "Kuwait",
    KZ: "Kazakhstan",
    LA: "Laos",
    LB: "Lebanon",
    LK: "Sri Lanka",
    LT: "Lithuania",
    LU: "Luxembourg",
    LV: "Latvia",
    LY: "Libyan Arab Jamahiriya",
    MA: "Morocco",
    MD: "Moldova",
    MG: "Madagascar",
    MK: "Macedonia",
    MN: "Mongolia",
    MO: "Macao",
    MQ: "Martinique",
    MT: "Malta",
    MU: "Mauritius",
    MV: "Maldives",
    MX: "Mexico",
    MY: "Malaysia",
    NC: "NewCaledonia",
    NG: "Nigeria",
    NI: "Nicaragua",
    NL: "Netherlands",
    NO: "Norway",
    NP: "Nepal",
    NZ: "New Zealand",
    OM: "Oman",
    PA: "Panama",
    PE: "Peru",
    PF: "French Polynesia",
    PH: "Philippines",
    PK: "Pakistan",
    PL: "Poland",
    PR: "Puerto Rico",
    PS: "Palestinian Territory",
    PT: "Portugal",
    PY: "Paraguay",
    QA: "Qatar",
    RE: "Reunion",
    RO: "Romania",
    RU: "Russia",
    SA: "Saudi Arabia",
    SD: "Sudan",
    SE: "Sweden",
    SG: "Singapore",
    SI: "Slovenia",
    SK: "Slovakia",
    SN: "Senegal",
    SV: "El Salvador",
    SY: "Syrian Arab Republic",
    TH: "Thailand",
    TN: "Tunisia",
    TR: "Turkey",
    TT: "Trinidad and Tobago",
    TW: "Taiwan",
    UA: "Ukraine",
    US: "United States",
    UY: "Uruguay",
    VE: "Venezuela",
    VN: "Vietnam",
    YE: "Yemen",
    ZA: "South Africa",
};

// tslint:disable-next-line:max-line-length
export const SHOPIFY_THEMES: string[] = [
    "alchemy",
    "artisan",
    "atlantic",
    "avenue",
    "blockshop",
    "boundless",
    "boost",
    "brooklyn",
    "california",
    "canopy",
    "capital",
    "cascade",
    "colors",
    "debut",
    "district",
    "editions",
    "editorial",
    "empire",
    "envy",
    "expression",
    "fashionopolism",
    "flow",
    "focal",
    "galleria",
    "grid",
    "handy",
    "icon",
    "impulse",
    "ira",
    "jumpstart",
    "kagami",
    "kingdom",
    "label",
    "launch",
    "local",
    "loft",
    "lorenza",
    "maker",
    "masonry",
    "minimal",
    "mobilia",
    "modular",
    "motion",
    "mosaic",
    "mr-parker",
    "narrative",
    "pacific",
    "palo-alto",
    "parallax",
    "pipeline",
    "pop",
    "prestige",
    "providence",
    "responsive",
    "reach",
    "retina",
    "showcase",
    "showtime",
    "simple",
    "split",
    "startup",
    "story",
    "sunrise",
    "supply",
    "symmetry",
    "testament",
    "trademark",
    "vantage",
    "venture",
    "venue",
    "vogue",
];

export const ALIEXPRESS_CATEGORIES: string[] = [
    "3",
    "34",
    "66",
    "200004360",
    "7",
    "13",
    "44",
    "5",
    "502",
    "2",
    "1503",
    "200003655",
    "42",
    "15",
    "6",
    "200001996",
    "36",
    "39",
    "1524",
    "1501",
    "21",
    "509",
    "30",
    "322",
    "200001075",
    "18",
    "1420",
    "26",
    "200003498",
    "1511",
    "320",
];
