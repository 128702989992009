import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { BackendService } from './backend.service';
import { HttpParams } from '@angular/common/http';
import { BOOKMARK_TYPE } from '../../../../../server/src/Constants';
import { IProductView } from '../../../../../server/src/interfaces/IProductView';
import { IStoreView } from '../../../../../server/src/interfaces/IStoreView';

@Injectable()
export class BookmarkSharedService {

  constructor(private backendService: BackendService) { }

  public addBookmark(sourceId: string, type: BOOKMARK_TYPE, item: IProductView | IStoreView) {
    const url = (environment.urls.members as any).createBookmark;
    const props = {
      type: type,
      sourceId,
      item,
    };
    return this.backendService.post(url, props);
  }

  public removeBookmark(id: string) {
    const url = (environment.urls.members as any).removeBookmark + id;
    const target = new HttpParams().set('bookmarkId', id);
    return this.backendService.delete(url, target);
  }

  public getBookmarks(keywords?: string): any {
    const url: string = (environment.urls.members as any).getBookmarks;
    const params = keywords ? { params: new HttpParams().set('keywords', keywords) } : new HttpParams();
    return this.backendService.get(url, params);
  }
}

