import {Component, Input, OnInit} from '@angular/core';
import {FacebookService} from 'ngx-facebook';
import {IStoreView} from '../../../../../../../../server/src/interfaces/IStoreView';

@Component({
  selector: 'app-popup-social-info',
  templateUrl: './popup-social-info.component.html',
  styleUrls: ['./popup-social-info.component.scss']
})
export class PopupSocialInfoComponent implements OnInit {
  @Input() store: IStoreView;
  params = {
    sourceType: 'profile',
    screenName: ''
  };
  opts = {
    height: 500,
    tweetLimit: 2,
    theme: 'dark'
  };

  constructor(
    private fb: FacebookService
  ) {}

  ngOnInit() {
    this.fb.init({
      status     : true,
      xfbml      : true,
      version    : 'v2.7'
    });
    this.params.screenName = this.store.twitterProfileName;

  }
}
