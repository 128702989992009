import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IBookmarkView } from '../../../../../../../../server/src/interfaces/IBookmarkView';
import { BOOKMARK_TYPE } from '../../../../../../../../server/src/Constants';
import { IProductView } from '../../../../../../../../server/src/interfaces/IProductView';

@Component({
  selector: 'app-aliexpress-product',
  templateUrl: './aliexpress-product.component.html',
  styleUrls: ['./aliexpress-product.component.scss']
})
export class AliExpressProductComponent implements OnInit {
  @Output() bookmark = new EventEmitter<object>();
  @Output() productSelected = new EventEmitter<IProductView>();
  @Output() productExpanded = new EventEmitter<IProductView>();
  @Input() products: IProductView[];
  @Input() selectedProduct: IProductView;
  @Input() bookmarks: IBookmarkView[];
  bookmarkType: string = BOOKMARK_TYPE.ALIEXPRESS_PRODUCT;

  constructor() { }

  ngOnInit() { }

  public checkBookmark(product) {
    if (this.bookmarks.length !== 0) {
      return this.bookmarks.some(item => item.sourceId === product);
    } else {
      return false;
    }
  }

  public toggleBookmark(product) {
    this.bookmark.emit({
      item: product,
      type: this.bookmarkType
    });
  }

  public onSelectProduct(product: IProductView) {
    this.productSelected.emit(product);
  }

  public onExpand(product: IProductView) {
    this.productExpanded.emit(product);
  }

}
