import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { BackendService } from '../../../shared/services/backend.service';
import { IStoreSearchRequest } from '../../../../../../server/src/interfaces/IStoreSearchRequest';
import { IStoreSearchResponse } from '../../../../../../server/src/interfaces/IStoreSearchResponse';
import { IGetStoreResponse } from '../../../../../../server/src/interfaces/IGetStoreResponse';


@Injectable()
export class ShopifyStoreSearchService {

  constructor(private backendService: BackendService) { }

  storeSearch(request: IStoreSearchRequest): Observable<IStoreSearchResponse> {
    const q: string = JSON.stringify(request);
    const url: string = environment.urls.members.storeSearch;
    return this.backendService
      .get(url, { params: new HttpParams().set('q', q) })
      .pipe(resp => resp);
  }

  storeIdSearch(id: string): Observable<IGetStoreResponse> {
    const url: string = environment.urls.members.getStore + '/' + id;
    const target = new HttpParams().set('id', id);
    return this.backendService.get(url, target);
  }
}
