import * as _ from 'lodash';

import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AdminService } from 'src/app/services/admin.service';
import { AlertService } from 'src/app/services/alert.service';
import { IStoreView } from '../../../../../../server/src/interfaces/IStoreView';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-domain-inspector',
  templateUrl: './domain-inspector.component.html',
  styleUrls: ['./domain-inspector.component.css']
})
export class DomainInspectorComponent implements OnInit {

  public isBusy: boolean;
  public form: FormGroup;
  public processed: boolean;
  public processing: boolean;
  public searched: boolean;
  public domainJson: string;
  public storeJson: string;
  public domain: any;
  public store: IStoreView;
  public lastErrorCode: string;

  constructor(
    private _route: ActivatedRoute,
    private _adminService: AdminService,
    private _alertService: AlertService,
  ) { }

  ngOnInit() {

    this.form = new FormGroup({
      domain: new FormControl('', [
        Validators.maxLength(500),
      ]),
    });

    this._route.params.subscribe(params => {
      const domainName: string = params['id'];
      this.form.setValue( { domain: domainName });
      this.process(domainName);
    });


  }
  public async onSubmit() {
    const domainName: string = this.form.value.domain;
    this.process(domainName);
  }

  public async deactivateStore() {

    this.isBusy = true;
    this.form.disable();
    this.processing = true;

    try {
      await this._adminService.deactivateStore(this.store.id).toPromise();
      const result = await this._adminService.getDomainStore(this.domain.id).toPromise();
      this.handleResult(result);
    } catch (error) {
      this._alertService.error('Deactivation Failed');
    }
    this.processing = false;

    this.isBusy = false;
    this.form.enable();

  }

  public async process(domainName: string) {

    this.isBusy = true;
    this.form.disable();

    let result: { store: IStoreView, domain: any };

    // Search for existing domain
    try {
      result = await this._adminService.getDomainStore(domainName).toPromise();
    } catch (error) {
      this._alertService.error('Finding domain Failed');
    }

    this.handleResult(result);

    this.searched = true;

    // If not found, attempt to create
    if (!result.domain) {
      this.processing = true;
      try {
        result = await this._adminService.processDomain(domainName).toPromise();
      } catch (error) {
        this._alertService.error('Processing Failed');
      }
      this.processing = false;
      this.handleResult(result);
    }

    this.isBusy = false;
    this.form.enable();

    this.processed = true;
  }

  public async reProcess() {
    this.isBusy = true;
    this.form.disable();

    const domainName: string = this.form.value.domain;
    let result: { store: IStoreView, domain: any };
    this.processing = true;
    try {
      result = await this._adminService.processDomain(domainName).toPromise();
    } catch (error) {
      this._alertService.error('Processing Failed');
    }
    this.processing = false;
    this.handleResult(result);

    this.isBusy = false;
    this.form.enable();

    this.processed = true;
  }

  public countDays(lastUpdate) {
    const curDate = Date.now();
    const lastUpdateMS = Date.parse(lastUpdate);
    return Math.ceil(Math.abs(curDate - lastUpdateMS) / (1000 * 3600 * 24)) - 1;
  }

  private handleResult(result: any) {
    if (result) {
      if (result.store) {
        this.storeJson = JSON.stringify(result.store, null, 4);
      } else {
        this.storeJson = 'Store entry not available';
      }

      if (result.domain) {
        this.domainJson = JSON.stringify(result.domain, null, 4);
        this.lastErrorCode = _.get(result, 'domain.lastErrorCode', 'NO ERRORS');
      } else {
        this.lastErrorCode = '';
        this.domainJson = null;
      }

      this.domain = result.domain;
      this.store = result.store;
    }
  }

}
